import React from "react";

function AboutCom() {
  return (
    <div>
      {" "}
      <div className="row mt-5 p-4">
        <div
          className="col-lg-6 pt-3"
          data-aos="fade-right"
          data-aos-delay={"0"}
        >
          <div className="card mb-3 box" style={{ minHeight: "380px" }}>
            <img
              className="pic card-img-top"
              style={{ height: "200px" }}
              src="/assets/imgs/in.png"
              alt="..."
            />
            <div
              className="card-body"
              // style={{ borderBottom: "3px solid dodgerblue" }}
            >
              <h4 className="card-title text-center">Integrity</h4>
              <br />
              <p className="card-text text-center">
                Our integrity in our work methodology has helped us create a
                strong system where growth & prosperity of each member of the
                RIL community is valued more than anything else.
              </p>
            </div>
          </div>
        </div>

        <div
          className="col-lg-6 pt-3"
          data-aos="fade-right"
          data-aos-delay={"100"}
        >
          <div className="card box1 mb-3" style={{ minHeight: "380px" }}>
            <img
              className="pic card-img-top"
              style={{ height: "200px" }}
              src="/assets/imgs/ll.png"
              alt="..."
            />
            <div
              className="card-body"
              // style={{ borderBottom: "3px solid  #006633" }}
            >
              <h4 className="card-title text-center">Loyality</h4>

              <p className="card-text text-center pb-5 pt-3 my-3">
                The loyality with which each Community member works here leads
                to a commitment to focus on self growth and make leaders
                personality on the whole community.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-4">
        <div className="col-lg-6" data-aos="fade-right" data-aos-delay={"100"}>
          <div className="card mb-3 box" style={{ minHeight: "370px" }}>
            <img
              className="pic card-img-top"
              style={{ height: "200px" }}
              src="/assets/imgs/cc.png"
              alt="..."
            />
            <div
              className="card-body"
              // style={{ borderBottom: "3px solid #ffd11a" }}
            >
              <h4 className="card-title text-center">Customer Happiness</h4>
              <p className="card-text text-center">
                Customer happiness is more important For us, so we worked on our
                Services And Products to give best for our Customers. Our
                customers are the backbone of our business, so we need to do
                whatever it takes to ensure you're happy. We know, a successful
                business is made up of happy customers.
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div
            className="card box1 mb-3"
            style={{ minHeight: "370px" }}
            data-aos="fade-right"
            data-aos-delay={"200"}
          >
            <img
              className="pic card-img-top"
              style={{ height: "200px" }}
              src="/Transparency-4.png"
              alt="..."
            />
            <div
              className="card-body"
              // style={{ borderBottom: "3px solid lime" }}
            >
              <h4 className="card-title text-center">Transparency</h4>

              <p className="card-text text-center my-3">
                Customers can expect transparency from RIL INDIA they're doing
                business with.Our all services for our Customers so everything
                is transparent for you and customers deserve openness from the
                RIL INDIA because you're part of our Community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutCom;
