import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "../../../Redux/common/action";
// import { URL } from "../../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";

import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
// import dateFormat, { masks } from "dateformat";

import ReactToPrint from "react-to-print";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";

function MyIncome() {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // getsellerProductList

  const get_seller_product_list = useSelector((state) =>
    state?.allapi?.my_direct_team ? state?.allapi?.my_direct_team : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );

  const all_sub_sub_categ_List = useSelector((state) =>
    state?.allapi?.all_sub_sub_categ_List
      ? state?.allapi?.all_sub_sub_categ_List
      : []
  );
  const my_income_list = useSelector((state) =>
    state?.allapi?.my_income_list ? state?.allapi?.my_income_list : []
  );

  console.log(my_income_list);
  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  //   useEffect(() => {
  //     dispatch(allapiAction.sellerDirectTeam(1));
  //     // dispatch(allapiAction.getInvoice({}));
  //     // dispatch(allapiAction.footerPagepageListetPu({}));

  //     return () => {};
  //   }, []);

  const [monthList, setmonthList] = useState([]);

  function getMonthList() {
    const startDate = new Date("2021-08-01");
    const currentDate = new Date();

    // Calculate the month one less than the current month
    currentDate.setMonth(currentDate.getMonth() - 1);

    const monthsList = [];

    while (startDate <= currentDate) {
      monthsList.push(
        startDate.toLocaleString("default", { month: "short", year: "numeric" })
      );

      startDate.setMonth(startDate.getMonth() + 1);
    }

    return monthsList;
  }

  useEffect(() => {
    const months = getMonthList();
    setmonthList(months);
    // console.log(months);
  }, []);

  // const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    { name: "Route Name", field: "routeName", sortable: true },
    { name: "From", field: "from", sortable: true },
    { name: "To", field: "to", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const comments = get_seller_product_list?.docs
    ? get_seller_product_list?.docs
    : [];

  const pageCount = get_seller_product_list?.totalPages
    ? get_seller_product_list?.totalPages
    : 0;

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //   computedComments = computedComments.filter(
    //     (comment) =>
    //       comment.message.toLowerCase().includes(search.toLowerCase()) ||
    //       comment.title.toLowerCase().includes(search.toLowerCase())
    //   );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  // useEffect(() => {
  //   const endOffset = itemOffset + itemsPerPage;

  //   setPageCount(Math.ceil(get_seller_product_list.totalDocs / itemsPerPage));
  // }, [itemOffset, itemsPerPage, comments]);

  // const handlePageClick = (event: any) => {
  //   const newOffset = event.selected % (comments.length / 2)
  //   setCurrentPage(newOffset + 1)
  //   setItemOffset(newOffset * itemsPerPage)
  // }

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1;

    // setpageNo(data)

    if (!search) {
      // dispatch(ShoetoggelAction.getusers(data))
      dispatch(allapiAction.sellerDirectTeam(data));
      //   dispatch(allapiAction.getsellerProductList(1));
      return () => {};
    } else {
      // dispatch(
      //   ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
      // );
      dispatch(allapiAction.searchSellerProduct({ key: search, page: data }));
      return () => {};
    }
  };

  // image uploder
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const [images1, setImages1] = React.useState([]);
  const maxNumber1 = 4;

  const onChange1 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages1(imageList);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  const [subList, setsubList] = useState([]);

  useEffect(() => {
    const dropList = all_sub_sub_categ_List?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [all_sub_sub_categ_List]);

  const [roledata, setroledata] = useState({ title: "", tegs: "" });

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setroledata({ ...roledata, [name]: value });
  };

  const hendleSearch = (e) => {
    // searchSellerProduct
    setSearch(e);
    // dispatch(allapiAction.searchSellerProduct(e));
    dispatch(allapiAction.searchSellerProduct({ key: e, page: 1 }));
    return () => {};
  };

  const submitProduct = () => {
    try {
      // const {data: auth} = await login(values.role_name, values.tegs)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData();

      // data.append("title", imageEditfor);

      // images

      if (images?.length == 0) {
        toast.error("please select feature Image");
      } else if (images1?.length == 0) {
        toast.error("please select  Image");
      } else if (!roledata?.title) {
        toast.error("please enter prodcat name");
      } else if (!roledata?.tegs) {
        toast.error("please enter product teg");
      } else if (!selectedOption) {
        toast.error("please select category ");
      } else {
        // setLoading(true);

        data.append("title", roledata?.title);
        data.append("tegs", roledata?.tegs);
        data.append("categoryName", "test cate");
        data.append("categoryId", selectedOption?._id);
        // data.append('sellerId', selectedOption1?._id)
        data.append("featureImage", images[0]?.file);
        images1?.forEach((file) => {
          data.append("images", file?.file);
        });

        dispatch(allapiAction.createSellerProduct(data));
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  // deleteSellerProduct

  const [showDelelt, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [productID, setproductID] = useState("");

  const hendleModalShow = (e) => {
    setShowDelete(true);
    setproductID(e);

    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const hendleDelete = () => {
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.deleteSellerProduct({ productId: productID }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const componentRef = useRef();

  const hendleShowOrder = (e) => {
    // getuserOrderDetails

    dispatch(allapiAction.getuserOrderDetails({ _id: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  // get_invoice

  const shippingCharge = order_details?.sub_total > 10000 ? 699 : 199;

  const totalSubtotalPricegstdd = order_details?.orderproductsList?.reduce(
    (total, obj) => {
      return total + (obj.sub_total * obj?.gst) / 100;
    },
    0
  );

  const totalSubtotalPricegst = Math.floor(totalSubtotalPricegstdd);

  const hendletoMonthReports = (e) => {
    console.log(e);

    // userMonthIncome

    dispatch(allapiAction.userMonthIncome({ monthyears: e }));
  };

  function getCurrentMonthYear() {
    const currentDate = new Date();
    const month = currentDate.toLocaleString("default", { month: "short" });
    const year = currentDate.getFullYear();
    return `${month} ${year}`;
  }

  useEffect(() => {
    const currentMonthYear = getCurrentMonthYear();

    dispatch(allapiAction.userMonthIncome({ monthyears: currentMonthYear }));
  }, []);

  var totalsell = 0;

  const hendleTochange =
    my_income_list &&
    my_income_list?.map((data) => {
      const comm = parseFloat(data?.commision);
      const tds = comm * 0.05;
      const netAmount = comm - tds;
      totalsell += comm;
    });
  console.log(totalsell);

  const tdsall = totalsell * 0.05;
  const netAmountb = totalsell - tdsall;

  return (
    <div>
      <Sidebar />

      <main className="main-wrap ">
        <HeaderAccount />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">My Income</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div>
              {/* <a href="#" className="btn btn-light rounded font-md">Export</a>
                      <a href="#" className="btn btn-light rounded font-md">Import</a> */}
              {/* <a href="#" className="btn btn-primary btn-sm rounded">
              Create new
            </a> */}

              {/* <Button variant="primary" onClick={handleShow}>
                Create Product
              </Button> */}
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header1">
              <div className="row align-items-center">
                {/* <div className="col col-check flex-grow-0">
                <div className="form-check ms-2">
                  <input className="form-check-input" type="checkbox" value="" />
                </div>
              </div> */}
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  {/* <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='currentColor'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span> */}
                  {/* <!--end::Svg Icon-->  */}
                  {/* <input
                    type="text"
                    data-kt-permissions-table-filter="search"
                    className="form-control form-control-solid w-250px ps-15"
                    placeholder="Search Product"
                    onChange={(e) => {
                      hendleSearch(e.target.value);
                    }}
                  /> */}
                  {/* <select className="form-select">
                                  <option selected>All category</option>
                                  <option>Electronics</option>
                                  <option>Clothes</option>
                                  <option>Automobile</option>
                              </select> */}
                </div>
                <div className="col-md-2 col-6">
                  {/* <input type="date" value="02.05.2021" className="form-control" /> */}
                </div>
                <div className="col-md-12 col-12">
                  <select
                    onChange={(e) => {
                      hendletoMonthReports(e.target?.value);
                    }}
                    className="form-select"
                  >
                    <option value={""} selected>
                      Select Month
                    </option>

                    {monthList &&
                      monthList?.map((data, i) => {
                        return (
                          <option value={data} key={i}>
                            {data}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </header>

            <div className="card-body">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ minWidth: "50px" }}>Refer ID</th>
                    <th style={{ minWidth: "50px" }}>Total Sale</th>
                    <th style={{ minWidth: "50px" }}>Amount</th>
                    <th style={{ minWidth: "50px" }}> TDS(5%)</th>
                    <th style={{ minWidth: "50px" }}>Net Amount</th>
                    <th style={{ minWidth: "50px" }}>Date</th>
                    <th style={{ minWidth: "150px" }}>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {my_income_list &&
                    my_income_list.map((data, i) => {
                      const comm = parseFloat(data?.commision);
                      const tds = comm * 0.05;
                      const netAmount = comm - tds;
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{data?.userId?.referId}</td>
                          <td>{data?.grand_total}</td>
                          <td>
                            {data?.commision + "(" + data?.per_commision + ")"}
                          </td>
                          <td>{tds}</td>
                          {/* <td>{data?.status}</td> */}
                          <td>{netAmount}</td>
                          <td>
                            {moment(data?.createdAt).format(
                              "DD MMM yyyy hh:mm"
                            )}
                          </td>
                          <td>{data?.per_commision_dis}</td>
                        </tr>
                      );
                    })}

                  <tr style={{ background: "yellow" }}>
                    <td colspan="3">Total</td>
                    <td>₹{totalsell}</td>
                    <td>₹{tdsall}</td>
                    <td colspan="3">₹{netAmountb}</td>
                    {/* <td>{data?.status}</td> */}
                    {/* <td>5</td> */}
                    {/* <td>6</td>
                    <td>7</td> */}
                  </tr>
                  <tr style={{ background: "green", color: "#ffffff" }}>
                    <td colspan="3"></td>

                    <td colspan="6">₹{totalsell}</td>
                  </tr>
                </tbody>
              </table>

              {/* {my_income_list?.length == "0" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <h3>Record Not found</h3>
                </div>
              )} */}
            </div>
          </div>

          {/* <div className="pagination-area mt-30 mb-50">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              className="dataTables_paginate paging_simple_numbers category"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div> */}
        </section>
      </main>
    </div>
  );
}

export default MyIncome;
