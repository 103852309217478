// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Head from "next/head";
// import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";
// import Footer from "../../../component/layout/Footer";
// import Header from "../../../component/layout/Header";
// import { ArticleJsonLd } from "next-seo";
// import { NextSeo } from "next-seo";
// import Image from "next/image";
// import { useParams } from "react-router-dom";
// import { allapiAction } from "../../Redux/common/action";
// import { URL } from "../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import ScrollToTop from "../ScrollToTop/ScrollToTop";
// import Seo from "../Seo/Seo";

function BlogsDetails({ post }) {
  //   const data = useParams();
  const dispatch = useDispatch();
  const router = useNavigate();
  const { id } = useParams();

//   const blog_details = post;

  const blog_details = useSelector((state) =>
    state?.allapi?.blog_details ? state?.allapi?.blog_details : {}
  );

  //   blog_details

  // console.log(blog_details);

  useEffect(() => {
    // getBlogListPub
    dispatch(allapiAction.getBlogDetails({ id: id }));
    return () => {};
  }, [id]);

  let pageUrl;

  const urltet = URL.API_BASE_URL_2 + "/blog-details/";

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      pageUrl = window.location.href;
    }
  }

  return (
    <div>
      {/* <Header /> */}
      {/* <ScrollToTop />
      <Seo
        pagLink={pageUrl}
        title={blog_details?.title}
        titleSeo={blog_details?.meta_title}
        seo_descriptions={blog_details?.meta_description}
        ogBlogDate={blog_details?.createdAt}
      /> */}
      <HeaderMain />
      {/* <NextSeo
        title={post?.title}
        description={post?.meta_description}
        canonical={urltet + id}
        openGraph={{
          // type: "website",
          locale: "en",

          siteName: "Jewelslly",
          url: urltet + id,
          title: post?.title,
          description: post?.meta_description,
          type: "article",
          article: {
            publishedTime: post?.createdAt,
            modifiedTime: post?.updatedAt,
            // expirationTime: '2022-12-21T22:04:11Z',
            // section: 'Section II',
            // authors: [
            //   'https://www.example.com/authors/@firstnameA-lastnameA',
            //   'https://www.example.com/authors/@firstnameB-lastnameB',
            // ],
            // tags: ['Tag A', 'Tag B', 'Tag C'],
          },
          images: [
            {
              url: URL.API_BASE_URL + post?.feature_image,
              width: 800,
              height: 600,
              alt: post?.title,
              type: "image/jpeg",
            },
          ],
        }}
        twitter={{
          handle: "@handle",
          site: "@jewelslly",
          cardType: "summary_large_image",
          title: post?.title,
          description: post?.meta_description,
        }}
      />
      <Head> */}
      {/* <title>{post?.title}</title>

        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="canonical" href={urltet + id} />

        <meta name="title" content={post?.meta_title} />
        <meta name="descriptions" content={post?.short_description} />

        <meta property="og:locale" content="en" />
        <meta property="og:url" content={urltet + id} />
        <meta property="og:site_name" content="Jewelslly" />
        <meta property="og:title" content={post?.meta_title} />
        <meta property="og:description" content={post?.meta_description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={URL.API_BASE_URL + post?.feature_image}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@jewelslly1" />
        <meta name="twitter:title" content={post?.meta_title} />
        <meta name="twitter:description" content={post?.meta_description} />
        <meta
          name="twitter:image"
          content={URL.API_BASE_URL + post?.feature_image}
        />
        <meta name="twitter:image:alt" content={post?.title} /> */}
      {/* </Head>
      <ArticleJsonLd
        type="BlogPosting"
        url={urltet + id}
        title={post?.title}
        images={[URL.API_BASE_URL + post?.feature_image]}
        datePublished={post?.createdAt}
        dateModified={post?.updatedAt}
        authorName="Jewelslly"
        description={post?.meta_description}
      /> */}

      <main className="main">
        <div className="page-content mb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 m-auto">
                <div className="single-page pt-50 pr-30">
                  <div className="single-header style-2">
                    <div className="row">
                      <div className="col-xl-10 col-lg-12 m-auto">
                        {/* <h6 className="mb-10">
                          <a href="#">Recipes</a>
                        </h6> */}
                        <h1 className="mb-10">{blog_details?.title}</h1>
                        <div className="single-header-meta">
                          <div className="entry-meta meta-1 font-xs mt-15 mb-15">
                            {/* <a className="author-avatar" href="#">
                              <img
                                className="img-circle"
                                src="assets/imgs/blog/author-1.png"
                                alt=""
                              />
                            </a> */}
                            <span className="post-by">
                              By <a href="#">Admin</a>
                            </span>
                            {/* <span className="post-on has-dot">2 hours ago</span>
                            <span className="time-reading has-dot">
                              8 mins read
                            </span> */}
                          </div>
                          <div className="social-icons single-share">
                            {/* <ul className="text-grey-5 d-inline-block">
                              <li className="mr-5">
                                <a href="#">
                                  <img
                                    src="assets/imgs/theme/icons/icon-bookmark.svg"
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <img
                                    src="assets/imgs/theme/icons/icon-heart-2.svg"
                                    alt=""
                                  />
                                </a>
                              </li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <figure className="single-thumbnail">
                    <Image
                      src={URL.API_BASE_URL + blog_details?.feature_image}
                      alt={blog_details?.title}
                      width={2000}
                      height={500}
                      // onError={(e) => {
                      //   e.target.onerror = null;
                      //   e.target.src = "/assets/imgs/notfound.png";
                      // }}
                    />
                  </figure>
                  <div className="single-content">
                    <div className="row">
                      <div className="col-xl-10 col-lg-12 m-auto">
                        <p className="single-excerpt">
                          {blog_details?.short_description}
                        </p>
                        <div
                          className="bolgBox"
                          dangerouslySetInnerHTML={{
                            __html: blog_details?.description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
      <FooterMain />
    </div>
  );
}

// export async function getServerSideProps({ params }) {
//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   var raw = JSON.stringify({
//     id: params.id,
//   });

//   var requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };

//   // console.log(raw);
//   // console.log(requestOptions);

//   const bgl = await fetch(
//     URL.API_BASE_URL + `${URL.getBlogDetails}`,
//     requestOptions
//   );
//   const events = await bgl.json();
//   // console.log(events);
//   // const postData = getPostData(slug);
//   const postData = events?.data;

//   if (!events) {
//     return { notFound: true };
//   }

//   return {
//     props: {
//       post: postData,
//       // postList: postDataList,
//     },
//   };
// }

export default BlogsDetails;
