// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import { NextSeo } from "next-seo";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";

// import { allapiAction } from "../../Redux/common/action";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";

function Transparency() {
  const dispatch = useDispatch();

  const [first, setfirst] = useState("0");

  // getlistfaqs
  // faqs_list
  const faqs_list = useSelector((state) =>
    state?.allapi?.faqs_list ? state?.allapi?.faqs_list : []
  );

  useEffect(() => {
    dispatch(allapiAction.getlistfaqs({}));
    return () => {};
  }, []);

  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      pageUrl = window.location.href;
    }
  }
  const urltet = URL.API_BASE_URL_2 + "/faq";

  const [switchValue, setSwitchValue] = useState(false);
  console.log(switchValue);
  const handleSwitchChange = (checked) => {
    console.log(checked?.target?.checked);
    if (checked?.target?.checked) {
      setSwitchValue(checked?.target?.checked);
      localStorage.setItem("lang", "hindi");
      dispatch(allapiAction.getlistfaqs({}));
    } else {
      setSwitchValue(checked?.target?.checked);
      localStorage.setItem("lang", "english");
      dispatch(allapiAction.getlistfaqs({}));
    }
  };

  const [value, setValue] = useState("");

  useEffect(() => {
    // if (typeof window !== "undefined") {
    const dsl = localStorage.getItem("lang");

    console.log(dsl);
    if (dsl) {
      console.log("teat1");
      if (dsl == "hindi") {
        console.log("teat2");
        setSwitchValue(true);
      } else {
        console.log("teat3");
        setSwitchValue(false);
      }
    }
    // } else {
    //   setValue("");
    // }
  }, []);
  return (
    <div>
      <HeaderMain />
      {/* <Header /> */}
      {/* <NextSeo
        title={"transparency"}
        description={"transparency"}
        canonical={urltet}
        openGraph={{
          // type: "website",
          locale: "en",

          siteName: "Jewelslly",
          url: urltet,
          title: "transparency",
          description: "transparency",
          type: "website",
          // profile: {
          //   firstName: post?.buseniss_id?.businessName,
          //   city: post?.city,
          //   country: post?.country,
          //   address: post?.buseniss_id?.address,
          //   // lastName: "Last",
          //   // username: "firstlast123",
          //   // gender: "female",
          // },
          // images: [
          //   {
          //     url: URL.API_BASE_URL + post?.buseniss_id?.logo,
          //     width: 800,
          //     height: 600,
          //     alt: post?.buseniss_id?.businessName,
          //     type: "image/jpeg",
          //   },
          // ],
        }}
        twitter={{
          handle: "@handle",
          site: "@jewelslly",
          cardType: "summary_large_image",
          title: "faq",
          description: "faq",
        }}
      /> */}

      <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="faq-title text-center faq-title2 pb-3">
                <h2 className="mt-10">TRANSPARENCY</h2>
              </div>
              <Form className="hin">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="हिंदी में पढ़े"
                  checked={switchValue}
                  onChange={handleSwitchChange}
                />
              </Form>
            </div>

            <div className="col-md-12 ">
              <div className="faq" id="accordion">
                {faqs_list &&
                  faqs_list?.map((data, i) => {
                    return (
                      <div className="card" key={i}>
                        <div
                          className="card-header"
                          id="faqHeading-1"
                          onClick={() => {
                            setfirst(i);
                          }}
                        >
                          <div className="mb-0">
                            <h5
                              className="faq-title newfaq"
                              data-toggle="collapse"
                              data-target="#faqCollapse-1"
                              data-aria-expanded="true"
                              data-aria-controls="faqCollapse-1"
                            >
                              <span className="badge">{i + 1}</span>
                              {data?.title}
                            </h5>
                          </div>
                        </div>
                        <div
                          id="faqCollapse-1"
                          className={first == i ? "" : "collapse"}
                          aria-labelledby="faqHeading-1"
                          data-parent="#accordion"
                        >
                          <div
                            className="card-body"
                            dangerouslySetInnerHTML={{
                              __html: data?.discription,
                            }}
                          ></div>
                        </div>
                      </div>
                    );
                  })}

                {/* <div className="card">
                  <div className="card-header" id="faqHeading-2">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-2"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-2"
                      >
                        <span className="badge">2</span> Where does it come from?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-2"
                    className="collapse"
                    aria-labelledby="faqHeading-2"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-3">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-3"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-3"
                      >
                        <span className="badge">3</span>Why do we use it?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-3"
                    className="collapse"
                    aria-labelledby="faqHeading-3"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-4">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-4"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-4"
                      >
                        <span className="badge">4</span> Where can I get some?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-4"
                    className="collapse"
                    aria-labelledby="faqHeading-4"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration in
                        some form, by injected humour, or randomised words which
                        don't look even slightly believable.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-5">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-5"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-5"
                      >
                        <span className="badge">5</span> What is Lorem Ipsum?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-5"
                    className="collapse"
                    aria-labelledby="faqHeading-5"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        {" "}
                        It has survived not only five centuries, but also the
                        leap into electronic typesetting, remaining essentially
                        unchanged. It was popularised in the 1960s with the
                        release of Letraset sheets containing
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-6">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-6"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-6"
                      >
                        <span className="badge">6</span> Where does it come from?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-6"
                    className="collapse"
                    aria-labelledby="faqHeading-6"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        The standard chunk of Lorem Ipsum used since the 1500s
                        is reproduced below for those interested. Sections
                        1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum"
                        by Cicero are also reproduced in their exact original
                        form, accompanied by English versions from the 1914
                        translation by H. Rackham.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="faqHeading-7">
                    <div className="mb-0">
                      <h5
                        className="faq-title"
                        data-toggle="collapse"
                        data-target="#faqCollapse-7"
                        data-aria-expanded="false"
                        data-aria-controls="faqCollapse-7"
                      >
                        <span className="badge">7</span> Why do we use it?
                      </h5>
                    </div>
                  </div>
                  <div
                    id="faqCollapse-7"
                    className="collapse"
                    aria-labelledby="faqHeading-7"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        Many desktop publishing packages and web page editors
                        now use Lorem Ipsum as their default model text, and a
                        search for 'lorem ipsum' will uncover many web sites
                        still in their infancy. Various versions have evolved
                        over the years, sometimes by accident, sometimes on
                        purpose (injected humour and the like).
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
      <FooterMain />
    </div>
  );
}

export default Transparency;
