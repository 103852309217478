// import { allapiAction } from "@/Redux/common/action";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import Button from "react-bootstrap/Button";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
// import Header2 from "../../../../component/sidebar/Header2";
import HeaderAccount from "../Layout/HeaderAccount";
import { URL } from "../../Redux/common/url";
// import Sidebar from "../../../../component/sidebar/Sidebar";

function Iidcard() {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  console.log(profile);

  useEffect(() => {
    dispatch(allapiAction.getprofile({}));

    return () => {};
  }, []);

  return (
    <div>
      <Sidebar />
      <main className="main-wrap">
        <HeaderAccount />
        <div className="p-5" style={{ overflow: "auto" }} ref={componentRef}>
          <div class="ril_id_box">
            <img
              src={"https://rilindia.biz/uploads/id-card/bg1.jpeg"}
              alt=""
              width="100%"
            />
            <div class="ril_di_card">
              <div class="watermark" style={{ display: "none" }}>
                <img
                  src="https://rilindia.biz/uploads/id-card/logo.png"
                  width="331"
                  alt=""
                />
              </div>
              <div class="ril_di_card_left">
                <img
                  src={
                    profile?.profile_image
                      ? URL?.API_BASE_URL + profile?.profile_image
                      : "https://rilindia.biz/uploads/user_image/default.jpg "
                  }
                  class="ril_img"
                  alt=""
                />
              </div>
              <div
                class="ril_di_card_right"
                style={{ textTransform: "capitalize" }}
              >
                <strong style={{ fontSize: "34px" }}>
                  {/* Mr. */}
                  {profile?.fullName}
                </strong>
                <strong>Id :{profile?.referId}</strong>
                <strong>
                  Rank :{" "}
                  {profile?.status == 0
                    ? "fc"
                    : profile?.status == 1
                    ? "fr"
                    : profile?.status == 2
                    ? "br"
                    : profile?.status == 3
                    ? "ct"
                    : profile?.status == 4
                    ? "Mq"
                    : "duke"}
                </strong>
              </div>
              <img
                src="https://rilindia.biz/uploads/id-card/logo.png"
                class="ril_logo"
                width="100"
                alt=""
              />
            </div>
          </div>

          <div class="ril_id_box">
            <img
              src="https://rilindia.biz/uploads/id-card/bg2.jpeg"
              alt=""
              width="100%"
            />
            <div class="ril_di_card" style={{ justifyContent: "left" }}>
              <p className="rilIDText">
                {" "}
                This identity card is issued by &apos;Resolved Improve Life
                India Marketing Pvt Ltd&apos; (RIL INDIA) to its Independent
                Business Owners who are enrolled with RIL INDIA as Direct Seller
                for promoting and selling of Our fashion products. Uses of this
                Card shall be governed by the terms and condition of RIL INDIA
                available at its website.{" "}
              </p>
              <div class="watermark" style={{ display: "none" }}>
                <img
                  src="https://rilindia.biz/uploads/id-card/logo.png"
                  width="331"
                  alt=""
                />
              </div>
              <div class="ril_di_card_top">
                <strong>Resolved Improve Life India</strong>
                <strong></strong>
              </div>
              <img
                src="https://rilindia.biz/uploads/id-card/logo.png"
                class="ril_logo"
                width="100"
                alt=""
              />
            </div>
          </div>
        </div>
        <ReactToPrint
          trigger={() => (
            <Button variant="primary">Print / Download</Button>
            // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
            //   Print / Download
            // </button>
          )}
          content={() => componentRef.current}
        />
      </main>
    </div>
  );
}

export default Iidcard;
