import React, { useEffect } from "react";

function MenuOutSideClick(ref, setData) {
  useEffect(() => {
    function Outclick(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setData(false);
      } else {
        setData(true);
      }
    }
    document.addEventListener("click", Outclick);
    return () => {
      document.removeEventListener("click", Outclick);
    };
  }, [ref]);
}

export default MenuOutSideClick;
