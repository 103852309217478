// import { allapiAction } from "@/Redux/common/action";
// import { useRouter } from "next/router";
import React, { useEffect } from "react";

// import { Audio, Puff, RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { allapiAction } from "../../Redux/common/action";
// import { useNavigate, useParams } from "react-router-dom";
// import { allapiAction } from "../../../Redux/common/action";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function ScureLogin() {
  //   const data = useParams();
  //   const navigate = useNavigate();

  const router = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();

  //   ltsWRd09eDk27C67ksrtl

  // console.log(id);

  //   const urlDecod = id.split("ltsWRd09eDk27C67ksrtl");

  useEffect(() => {
    // signinSellereSecurite

    dispatch(allapiAction.signinSellereSecurite({ userDetails: id }, router));
    return () => {};
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      // setTimeout(() => {
      // deshboard_state?.proCount
      router("/home");
      // }, 1000);
    }
  }, []);

  return (
    <div className="loadingPage">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: "100%",
        }}
      >
        {/* <Audio
          type="TailSpin"
          color="#047475"
          height={100}
          width={100}
        />
        <Puff
  height="80"
  width="80"
  radius={1}
  color="#4fa94d"
  ariaLabel="puff-loading"
  wrapperStyle={{}}
  wrapperclassName=""
  visible={true}
/> */}
        {/* <RotatingLines
          strokeColor="#4fa94d"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <BounceLoader color="#0D6EFD" />
        </div>
      </div>
    </div>
  );
}

export default ScureLogin;
