// import Image from "next/image";
import React, { useState } from "react";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";

import PhoneInput from "react-phone-number-input";
import { Image } from "react-bootstrap";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";
import { allapiAction } from "../../Redux/common/action";

function Contact() {
  const [userDetails, setuserDetails] = useState({});
  const [value, setValue] = useState("");

  const dispatch = useDispatch();

  const hendleChange = (e) => {
    const { name, value } = e.target;

    setuserDetails({ ...userDetails, [name]: value });
  };

  const hendleSubmit = (e) => {
    e.preventDefault();

    if (!userDetails?.name) {
      toast.error("Please enter name");
    } else if (!userDetails?.email) {
      toast.error("Please enter email");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(userDetails?.email)
    ) {
      toast.error("invalid email");
    } else if (!value) {
      toast.error("Please enter number");
    } else if (!userDetails?.message) {
      toast.error("Please enter message");
    } else if (userDetails?.message > 500) {
      toast.error("you can max 500 charter enter for message");
    } else {
      dispatch(
        allapiAction.createContactqueries({
          email: userDetails?.email,
          name: userDetails?.name,
          number: value,
          message: userDetails?.message,
        })
      );
      setuserDetails({ name: "", email: "", number: "", message: "" });
      setValue("");
      return () => {};
    }

    // createContactqueries
  };

  return (
    <div>
      {/* <Header /> */}
      <HeaderMain />
      <div className="container mb-30    ">
        <div className="col-xl-10 col-lg-12 m-auto">
          <div className="row">
            <div className="col-xl-8 ">
              <div className="contact-from-area padding-20-row-col">
                <h5 className="text-brand mb-10">Contact Us</h5>
                {/* <h2 className="mb-10">Drop Us a Line</h2> */}
                {/* <p className="text-muted mb-30 font-sm">
                  Your email address will not be published. Required fields are
                  marked *
                </p> */}
                <form
                  className="contact-form-style mt-30"
                  id="contact-form"
                  // action="#"
                  // method="post"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          name="name"
                          placeholder="Name"
                          type="text"
                          value={userDetails?.name}
                          onChange={(e) => {
                            hendleChange(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          name="email"
                          placeholder="Your Email"
                          type="email"
                          value={userDetails?.email}
                          onChange={(e) => {
                            hendleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="input-style mb-20">
                        <PhoneInput
                          placeholder="Enter phone number"
                          country="IN"
                          defaultCountry="IN"
                          value={value}
                          onChange={setValue}
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-12 col-md-12">
                      <div className="input-style mb-20">
                        <input
                          name="number"
                          placeholder="Your Phone"
                          type="tel"
                          value={userDetails?.number}
                          onChange={(e) => {
                            hendleChange(e);
                          }}
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          name="subject"
                          placeholder="Subject"
                          type="text"
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-12 col-md-12">
                      <div className="textarea-style mb-30">
                        <textarea
                          name="message"
                          value={userDetails?.message}
                          placeholder="Message"
                          onChange={(e) => {
                            hendleChange(e);
                          }}
                        ></textarea>
                      </div>
                      <button
                        className="submit submit-auto-width"
                        type="submit"
                        onClick={(e) => {
                          hendleSubmit(e);
                        }}
                      >
                        Send message
                      </button>
                    </div>
                    <div className="col-lg-12 col-md-12 mt-30">
                      <h6>QUERIES / FEEDBACK / COMPLAINTS</h6>
                      <p>
                        Below channel may be used to submit any query / feedback
                        / complaint through any of our access channels mentioned
                        below:
                      </p>
                      <p>Chat with Us: Whats'sapp click here</p>
                      <p>
                        Calling Us: We will be happy to attend your calls at
                        18004198684 between 10:00 am - 6:00 pm from Monday to
                        Saturday
                      </p>
                      <p>
                        Email Us:{" "}
                        <a href="#">care@resolvedimprovelifeindia.com </a>{" "}
                      </p>
                      <p>
                        Deadline: Deadline for resolution of complaint is 20
                        days. However, most of the cases are solved within
                        a few days only.
                      </p>
                    </div>
                  </div>
                </form>
                <p className="form-messege"></p>
              </div>
            </div>
            <div className="col-lg-4 pl-30 d-lg-block ">
              <Image
                className="border-radius-15 mt-50"
                src="/Contact Us-3.png"
                alt="contact"
                width={350}
                height={450}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/assets/imgs/notfound.png";
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <FooterMain />
    </div>
  );
}

export default Contact;
