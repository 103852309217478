// import { allapiAction } from "@/Redux/common/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Footerr from "../../../component/sidebar/Footerr";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button, Modal } from "react-bootstrap";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
function ChallangeMarquis() {
  const dispatch = useDispatch();
  const profile222 = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );

  const profile = useSelector((state) =>
    state?.allapi?.get_buss_pro_1 ? state?.allapi?.get_buss_pro_1 : {}
  );
  const challange_data = useSelector((state) =>
    state?.allapi?.challange_data ? state?.allapi?.challange_data : {}
  );
  const user_income_date = useSelector((state) =>
    state?.allapi?.user_income_date ? state?.allapi?.user_income_date : {}
  );
  console.log(profile);
  console.log(challange_data);
  console.log(user_income_date?.amount);
  const [remainingday, setremainingday] = useState(0);
  useEffect(() => {
    if (challange_data?.chalengeDateApproved) {
      const originalDate2 = moment(
        challange_data?.chalengeDateApproved,
        "YYYY-MM-DD"
      );
      const daycheck2 =
        challange_data?.chalengecycle == 0 ? 0 : challange_data?.chalengecycle;

      // console.log(originalDate2);
      // console.log(daycheck2);
      const futureDate2 =
        daycheck2 > 0 ? originalDate2.add(daycheck2, "months") : originalDate2;

      // console.log(futureDate2);
      var today = new Date();
      var targetDate = new Date(futureDate2);

      const datetimeObj = moment(targetDate);

      const month = datetimeObj.month() + 1; // Moment.js months are 0-indexed
      const year = datetimeObj.year();

      const startDate = new Date(year, month - 1, 1);
      console.log(startDate);
      console.log(targetDate);
      console.log(today);
      const originalDate24 = moment(startDate, "YYYY-MM-DD");
      const remaDate = moment(originalDate24.add(4, "months"));
      var timeDifference = remaDate - today;
      // var timeDifference = today - startDate;

      // Convert milliseconds to days
      var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      setremainingday(daysDifference);
      //   console.log("Difference in days:", daysDifference);
    }
  }, [challange_data]);

  useEffect(() => {
    dispatch(allapiAction.getChelenge({ status: "4" }));

    return () => {};
  }, []);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());

  //   userstatement

  const [showDelelt, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const hendleToChackReport = () => {
    dispatch(
      allapiAction.createChelenge({
        status: "4",
      })
    );
    setShowDelete(false);
    return () => {};
  };
  return (
    <div>
      {" "}
      <div>
        <Sidebar />

        <Modal show={showDelelt} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title> Submit Challenge</Modal.Title>
          </Modal.Header>
          <Modal.Body>Challenge Amount 1550000</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              No
            </Button>
            <Button variant="primary" onClick={hendleToChackReport}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <main className="main-wrap">
          <HeaderAccount />

          <section className="content-main">
            <div className="content-header">
              <div>
                <h2 className="content-title card-title">Challenge Marquis</h2>
                {/* <p>Whole data about your business here</p> */}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div id="profile_content">
                  {" "}
                  <div
                    class="information-title"
                    style={{ marginBottom: "0px" }}
                  >
                    Challenge Marquis
                  </div>
                  <div className="row ">
                    <div className="col-4">
                      {/* <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      /> */}
                    </div>
                    <div className="col-4">
                      {/* <DatePicker
                        selected={startDate2}
                        onChange={(date) => setStartDate2(date)}
                      /> */}
                    </div>
                    <div className="col-4">
                      <div
                        onClick={() => {
                          setShowDelete(true);
                        }}
                        className="btn btn-primary font-sm btn-light rounded mt-5"
                      >
                        Submit Challenge
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div
                        class="recent-post"
                        style={{
                          background: "#fff",
                          border: "1px solid #e0e0e0",
                        }}
                      >
                        <div class="media">
                          <div
                            class="media-body"
                            style={{ paddingRight: "20px" }}
                          >
                            {Object.keys(challange_data).length > 0 && (
                              <table
                                class="table table-condensed"
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "0px",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <b>UserID</b>
                                    </td>
                                    <td>
                                      <b>Send Request Date</b>
                                    </td>
                                    <td>Target Days</td>
                                    <td>
                                      <b>Target Amount</b>
                                    </td>
                                    <td>
                                      <b>Status</b>
                                    </td>
                                    <td>
                                      <b>Remaining Days</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>{challange_data?.userId?.referId}</b>
                                    </td>
                                    <td align="left">
                                      {challange_data?.chalengeDateApproved}
                                    </td>
                                    <td>
                                      <b>4 Month</b>
                                    </td>
                                    <td>
                                      <span>
                                        <b>
                                          ₹{" "}
                                          {challange_data?.amount
                                            ? challange_data?.amount
                                            : 0}
                                        </b>
                                        <br />
                                        <b>
                                          ₹{" "}
                                          {challange_data?.bussamount
                                            ? challange_data?.bussamount
                                            : 0}
                                        </b>
                                      </span>{" "}
                                    </td>
                                    <td align="left">
                                      {challange_data?.chalengeApproved}
                                    </td>
                                    <td align="left">{remainingday}</td>
                                  </tr>

                                  {/* <tr>
                                  <td>
                                    <b>Rank</b>
                                  </td>
                                  <td style={{ color: "green" }}>
                                    {" "}
                                    <b>
                                      {" "}
                                      {profile?.status == 0
                                        ? "FC"
                                        : profile?.status == 1
                                        ? "FR"
                                        : profile?.status == 2
                                        ? "BR"
                                        : profile?.status == 3
                                        ? "CT"
                                        : profile?.status == 4
                                        ? "MQ"
                                        : "DUKE"}
                                    </b>
                                  </td>
                                  <td>
                                    <b>Other Deduction</b>
                                  </td>
                                  <td>₹ 0.0</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Status</b>
                                  </td>
                                  <td align="left" style={{ color: "green" }}>
                                    <b>{profile?.user_status} </b>
                                  </td>
                                  <td>
                                    {" "}
                                    <b>Total Payable</b>
                                  </td>
                                  <td>
                                    {" "}
                                    <b>
                                      ₹{" "}
                                      {user_income_date?.netAmount
                                        ? user_income_date?.netAmount
                                        : 0}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Sponsor FCID </b>
                                  </td>
                                  <td>{profile?.referByuserId?.referId}</td>
                                  <td>
                                    <b>Pan No</b>
                                  </td>
                                  <td>-----</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Sponsor Name </b>
                                  </td>
                                  <td>{profile?.referByuserId?.fullName}</td>
                                  <td>
                                    <b>Account No</b>
                                  </td>
                                  <td>-----</td>
                                </tr> */}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-md-12 dashboard-hides"
                      style={{ display: "none" }}
                    >
                      <i class="fa fa-spinner"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <Footerr /> */}
        </main>
      </div>
    </div>
  );
}

export default ChallangeMarquis;
