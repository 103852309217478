import React, { useEffect, useMemo, useRef, useState, memo } from "react";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";
import moment from "moment";
import DatePicker from "react-datepicker";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";

function TodayBussniss() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const seller_date_reports = useSelector((state) =>
    state?.allapi?.seller_date_reports ? state?.allapi?.seller_date_reports : {}
  );

  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard_state ? state?.allapi?.deshboard_state : {}
  );

  console.log(seller_date_reports);
  console.log(deshboard);

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1); // Subtract one day

  console.log(currentDate);

  useEffect(() => {
    // dispatch(allapiAction.getsellerprofilebyseller({}));
    // dispatch(allapiAction.getuserBussnissMonth({}));
    // return () => {};

    dispatch(
      allapiAction.sellerDateWiseReport({
        startDate: currentDate,
        endDate: startDate2,
      })
    );
    return () => {};
  }, []);

  const hendleToChackReport = () => {
    dispatch(
      allapiAction.sellerDateWiseReport({
        startDate: startDate,
        endDate: startDate2,
      })
    );
    return () => {};
  };
  const range = (start, end, step = 1) => {
    const array = [];
    for (let i = start; i < end; i += step) {
      array.push(i);
    }
    return array;
  };

  return (
    <div>
      <Sidebar />

      <main className="main-wrap">
        <HeaderAccount />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Today Business</h2>
            </div>
            <div></div>
          </div>

          <div className="card mb-4">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="widget widget-categories"
                  style={{ paddingBottom: "25px", minHeight: "70vh" }}
                >
                  <ul class="profile_ul">
                    <li>
                      <a href="#">
                        {deshboard &&
                          moment(seller_date_reports?.endDate).format(
                            "DD MMM yyyy"
                          )}
                        <b>
                          :<i class="fa fa-inr"> </i>
                          {seller_date_reports?.amount}
                        </b>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default TodayBussniss;
