import React, { useEffect, useMemo, useRef, useState, memo } from "react";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";
import moment from "moment";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
function RankReport() {
  const dispatch = useDispatch();

  const seller_update_details = useSelector((state) =>
    state?.allapi?.seller_update_details
      ? state?.allapi?.seller_update_details
      : {}
  );

  console.log(seller_update_details);

  useEffect(() => {
    dispatch(allapiAction.getsellerprofilebyseller({}));

    return () => {};
  }, []);

  return (
    <div>
      <Sidebar />

      <main className="main-wrap">
        <HeaderAccount />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Rank Upgrade Report</h2>
            </div>
            <div></div>
          </div>
          <div className="card mb-4">
            <div className="mb-10 d-flex p-5">
              <div className="col-12">
                {/*            
                <div className="d-flex">
                  <div className="col-3">
                    <h6>Name</h6>
                  </div>
                  <div className="col-9">
                    {" "}
                    <p>{seller_update_details?.fullName}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <h6>Email</h6>
                  </div>
                  <div className="col-9">
                    {" "}
                    <p>{seller_update_details?.email}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <h6>Role</h6>
                  </div>
                  <div className="col-9">
                    {" "}
                    <p>{seller_update_details?.role_name}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <h6>Balance</h6>
                  </div>
                  <div className="col-9">
                    {" "}
                    <p>{seller_update_details?.balance}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <h6>User Id</h6>
                  </div>
                  <div className="col-9">
                    {" "}
                    <p>{seller_update_details?.referId}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <h6>Upline Id</h6>
                  </div>
                  <div className="col-9">
                    {" "}
                    <p>{seller_update_details?.referBy}</p>
                  </div>
                </div> */}

                <h3> Rank Upgrade Status</h3>

                <div className="rankrole">
                  <table>
                    <tr>
                      {/* <th>Company</th> */}
                      <th>Rank name</th>
                      <th>Rank Status</th>
                      {/* <th>Rank Down date</th> */}
                      {/* <th>Description</th> */}
                    </tr>

                    {seller_update_details?.RankdownUpdata &&
                      seller_update_details?.RankdownUpdata?.map((data, i) => {
                        const userrank =
                          data?.rankName == 0
                            ? "FASHION CONSULTANT"
                            : data?.rankName == 1
                            ? "FRANCHISE"
                            : data?.rankName == 2
                            ? "BARON"
                            : data?.rankName == 3
                            ? "COUNT"
                            : data?.rankName == 4
                            ? "MARQUISE"
                            : data?.rankName == 5
                            ? "DUKE"
                            : data?.rankName == 6
                            ? "ARCH DUKE"
                            : "";

                        return (
                          <tr key={i}>
                            <td>{userrank}</td>
                            <td>
                              {data?.rankupDate &&
                                moment(data?.rankupDate).format(
                                  "MMM DD, yyyy hh:mm:ss A"
                                )}
                            </td>
                            {/* <td>
                                  {data?.rankdownDate &&
                                    moment(data?.rankdownDate).format('MMM DD, yyyy hh:mm:ss A')}
                                </td> */}
                            {/* <td>{data?.description}</td> */}
                          </tr>
                        );
                      })}

                    {/* <tr>
                          <td>Centro comercial Moctezuma</td>
                          <td>Francisco Chang</td>
                          <td>Mexico</td>
                        </tr>
                        <tr>
                          <td>Ernst Handel</td>
                          <td>Roland Mendel</td>
                          <td>Austria</td>
                        </tr>
                        <tr>
                          <td>Island Trading</td>
                          <td>Helen Bennett</td>
                          <td>UK</td>
                        </tr>
                        <tr>
                          <td>Laughing Bacchus Winecellars</td>
                          <td>Yoshi Tannamuri</td>
                          <td>Canada</td>
                        </tr>
                        <tr>
                          <td>Magazzini Alimentari Riuniti</td>
                          <td>Giovanni Rovelli</td>
                          <td>Italy</td>
                        </tr> */}
                  </table>
                </div>
                {/* <div className='d-flex mb-5'>
                      <div className='col-8'>
                        <div className='d-flex'>
                          <div className='col-3'>
                            <h6>fr date</h6>
                          </div>
                          <div className='col-9'>
                            {' '}
                            <p>{seller_update_details?.Userchaindata?.fr_date}</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <div className='col-3'>
                            <h6>br date</h6>
                          </div>
                          <div className='col-9'>
                            {' '}
                            <p>{seller_update_details?.Userchaindata?.br_date}</p>
                          </div>
                        </div>
                      </div>
                    </div> */}

                {/* <h6>Bussniss Name</h6>
                      <p>{seller_update_details?.buseniss_id?.businessName}</p>
                      <h6>City </h6>
                      <p>{seller_update_details?.buseniss_id?.city}</p>
                      <h6>Country </h6>
                      <p>{seller_update_details?.buseniss_id?.country}</p>
                      <h6>Address </h6>
                      <p>{seller_update_details?.buseniss_id?.address}</p> */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default RankReport;
