import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "../../../Redux/common/action";
// import { URL } from "../../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";

import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
// import dateFormat, { masks } from "dateformat";

import ReactToPrint from "react-to-print";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";

function Wallet() {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // getsellerProductList

  const get_seller_product_list = useSelector((state) =>
    state?.allapi?.get_seller_transaction_list
      ? state?.allapi?.get_seller_transaction_list
      : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  const all_sub_sub_categ_List = useSelector((state) =>
    state?.allapi?.all_sub_sub_categ_List
      ? state?.allapi?.all_sub_sub_categ_List
      : []
  );
  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  useEffect(() => {
    dispatch(allapiAction.getsellerTransaction(1));
    dispatch(allapiAction.getInvoice({}));
    dispatch(allapiAction.footerPagepageListetPu({}));

    return () => {};
  }, []);

  // useEffect(() => {
  //   dispatch(allapiAction.getsubsubcate({}));
  //   return () => {};
  // }, []);

  // const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    { name: "Route Name", field: "routeName", sortable: true },
    { name: "From", field: "from", sortable: true },
    { name: "To", field: "to", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const comments = get_seller_product_list?.docs
    ? get_seller_product_list?.docs
    : [];

  const pageCount = get_seller_product_list?.totalPages
    ? get_seller_product_list?.totalPages
    : 0;

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //   computedComments = computedComments.filter(
    //     (comment) =>
    //       comment.message.toLowerCase().includes(search.toLowerCase()) ||
    //       comment.title.toLowerCase().includes(search.toLowerCase())
    //   );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  // useEffect(() => {
  //   const endOffset = itemOffset + itemsPerPage;

  //   setPageCount(Math.ceil(get_seller_product_list.totalDocs / itemsPerPage));
  // }, [itemOffset, itemsPerPage, comments]);

  // const handlePageClick = (event: any) => {
  //   const newOffset = event.selected % (comments.length / 2)
  //   setCurrentPage(newOffset + 1)
  //   setItemOffset(newOffset * itemsPerPage)
  // }
  const [page, setpageNo] = useState(1);
  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1;

    setpageNo(data);

    if (!search) {
      // dispatch(ShoetoggelAction.getusers(data))
      dispatch(allapiAction.getsellerTransaction(data));
      //   dispatch(allapiAction.getsellerProductList(1));
      return () => {};
    } else {
      // dispatch(
      //   ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
      // );
      dispatch(allapiAction.searchSellerProduct({ key: search, page: data }));
      return () => {};
    }
  };

  // image uploder
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const [images1, setImages1] = React.useState([]);
  const maxNumber1 = 4;

  const onChange1 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages1(imageList);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  const [subList, setsubList] = useState([]);

  useEffect(() => {
    const dropList = all_sub_sub_categ_List?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [all_sub_sub_categ_List]);

  const [roledata, setroledata] = useState({ title: "", tegs: "" });

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setroledata({ ...roledata, [name]: value });
  };

  const hendleSearch = (e) => {
    // searchSellerProduct
    setSearch(e);
    // dispatch(allapiAction.searchSellerProduct(e));
    dispatch(allapiAction.searchSellerProduct({ key: e, page: 1 }));
    return () => {};
  };

  const submitProduct = () => {
    try {
      // const {data: auth} = await login(values.role_name, values.tegs)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData();

      // data.append("title", imageEditfor);

      // images

      if (images?.length == 0) {
        toast.error("please select feature Image");
      } else if (images1?.length == 0) {
        toast.error("please select  Image");
      } else if (!roledata?.title) {
        toast.error("please enter prodcat name");
      } else if (!roledata?.tegs) {
        toast.error("please enter product teg");
      } else if (!selectedOption) {
        toast.error("please select category ");
      } else {
        // setLoading(true);

        data.append("title", roledata?.title);
        data.append("tegs", roledata?.tegs);
        data.append("categoryName", "test cate");
        data.append("categoryId", selectedOption?._id);
        // data.append('sellerId', selectedOption1?._id)
        data.append("featureImage", images[0]?.file);
        images1?.forEach((file) => {
          data.append("images", file?.file);
        });

        dispatch(allapiAction.createSellerProduct(data));
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  // deleteSellerProduct

  const [showDelelt, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [productID, setproductID] = useState("");

  const hendleModalShow = (e) => {
    setShowDelete(true);
    setproductID(e);

    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const hendleDelete = () => {
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.deleteSellerProduct({ productId: productID }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const componentRef = useRef();

  const hendleShowOrder = (e) => {
    // getuserOrderDetails

    dispatch(allapiAction.getuserOrderDetails({ _id: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  // get_invoice

  return (
    <div>
      <Sidebar />

      <main className="main-wrap ">
        <HeaderAccount />

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Product Add</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="card mx-auto ">
              <div className="card-body">
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Feature Image
                  </label>
                  <ImageUploading
                    // multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button>
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="mb-3">
                  <label for="title" className="form-label">
                    Product Name
                  </label>
                  <input
                    name="title"
                    className="form-control"
                    placeholder="Enter Product name"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label for="teg" className="form-label">
                    Product Tag
                  </label>
                  <input
                    name="tegs"
                    className="form-control"
                    placeholder="Enter tag"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Select Category</label>
                  <Select
                    className="coustomSelect"
                    classNamePrefix="coustomSelect"
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={subList}
                  />
                  {/* <select className="form-select">
                  <option>Automobiles</option>
                  <option>home items</option>
                  <option>Electronics</option>
                  <option>Smartphones</option>
                  <option>Sport items</option>
                  <option>Baby and Tous</option>
                </select> */}
                </div>

                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Product Iamge
                  </label>
                  <ImageUploading
                    multiple
                    value={images1}
                    onChange={onChange1}
                    maxNumber={maxNumber1}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button>
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                submitProduct();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDelelt} size="lg" onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title> Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container img100" ref={componentRef}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="invoice-inner">
                    <div className="invoice-info" id="invoice_wrapper">
                      <div className="invoice-header">
                        <div className="row invoceCantiner">
                          <div className="col-sm-6">
                            <div className="invoice-numb">
                              <h1 className="invoice-header-1 mb-10 mt-20">
                                Invoice
                                {/* <span className="text-brand">#1970191</span> */}
                              </h1>
                              <h6 className="">Dear, {productID?.userName} </h6>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="invoice-name text-end">
                              <div>
                                <div className="logo text-end">
                                  {/* <a href="index.html"> */}
                                  <img
                                    src={
                                      URL.API_BASE_URL +
                                      get_footer_all_data?.socialIcon
                                        ?.invoiceLogo
                                    }
                                    alt="logo"
                                  />
                                  {/* </a> */}
                                </div>

                                <p className="text-sm text-mutted">
                                  {get_footer_all_data?.socialIcon?.companyName}
                                </p>
                                <p className="text-sm text-mutted">
                                  {get_footer_all_data?.socialIcon?.email}
                                </p>
                                <p className="text-sm text-mutted">
                                  {get_footer_all_data?.socialIcon?.address}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-top">
                        <div className="row">
                          <div className="col-lg-9 col-md-6">
                            {/* <div className="invoice-number">
                              <h4 className="invoice-title-1 mb-10">Invoice To</h4>
                              <p className="invoice-addr-1">
                                <strong>AliThemes Pty Ltd</strong> <br />
                                contactalithemes.com <br />
                                PO Box 16122, Collins Street West, <br />
                                Australia
                              </p>
                            </div> */}
                          </div>
                          <div className="col-lg-3 col-md-6">
                            {/* <div className="invoice-number">
                              <h4 className="invoice-title-1 mb-10">Bill To</h4>
                              <p className="invoice-addr-1">
                                <strong>NestMart Inc</strong> <br />
                                billing@NestMart.com <br />
                                205 North Michigan Avenue, <br />
                                Suite 810 Chicago, 60601, USA
                              </p>
                            </div> */}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-lg-9 col-md-6">
                            <h4 className="invoice-title-1 mb-10">
                              {/* Dear, {productID?.userName}{" "} */}
                            </h4>
                            {/* <p className="invoice-from-1">30 November 2022</p> */}
                          </div>
                          <div className="col-lg-3 col-md-6">
                            {/* <h4 className="invoice-title-1 mb-10">
                              Payment Method
                            </h4>
                            <p className="invoice-from-1">Via Paypal</p> */}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-lg-9 col-md-6">
                            <h4 className="invoice-title-1 mb-10">Order Id</h4>
                            <p className="invoice-from-1">{productID?._id}</p>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <h4 className="invoice-title-1 mb-10">Date</h4>
                            <p className="invoice-from-1">
                              {" "}
                              {/* {dateFormat(productID?.createdAt, " d mmm yyyy")} */}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="invoice-center">
                        <div className="table-responsive">
                          <table className="table table-striped invoice-table">
                            <thead className="bg-active">
                              <tr>
                                <th>Package Name</th>
                                <th className="text-center"> </th>
                                <th className="text-center">Product Count</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* productID */}
                              <tr>
                                <td>
                                  <div className="item-desc-1">
                                    <span>{productID?.packageName}</span>
                                    {/* <small>SKU: FWM15VKT</small> */}
                                  </div>
                                </td>
                                <td className="text-center"></td>
                                <td className="text-center">
                                  {productID?.packageId?.productCount}
                                </td>
                                <td className="text-right">
                                  ₹{productID?.packageId?.amount}
                                </td>
                              </tr>
                              {/* <tr>
                                <td>
                                  <div className="item-desc-1">
                                    <span>
                                      Blue Diamond Almonds Lightly Salted
                                    </span>
                                    <small>SKU: FWM15VKT</small>
                                  </div>
                                </td>
                                <td className="text-center">$20.00</td>
                                <td className="text-center">3</td>
                                <td className="text-right">$60.00</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="item-desc-1">
                                    <span>
                                      Fresh Organic Mustard Leaves Bell Pepper
                                    </span>
                                    <small>SKU: KVM15VK</small>
                                  </div>
                                </td>
                                <td className="text-center">$640.00</td>
                                <td className="text-center">1</td>
                                <td className="text-right">$640.00</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="item-desc-1">
                                    <span>
                                      All Natural Italian-Style Chicken
                                      Meatballs
                                    </span>
                                    <small>SKU: 98HFG</small>
                                  </div>
                                </td>
                                <td className="text-center">$240.00</td>
                                <td className="text-center">1</td>
                                <td className="text-right">$240.00</td>
                              </tr> */}
                              <tr>
                                <td colspan="3" className="text-end f-w-600">
                                  SubTotal
                                </td>
                                <td className="text-right">
                                  ₹{productID?.packageId?.amount}
                                </td>
                              </tr>
                              {/* <tr>
                                <td colspan="3" className="text-end f-w-600">
                                  Tax
                                </td>
                                <td className="text-right">$85.99</td>
                              </tr> */}
                              <tr>
                                <td colspan="3" className="text-end f-w-600">
                                  Grand Total
                                </td>
                                <td className="text-right f-w-600">
                                  ₹{productID?.packageId?.amount}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="invoice-bottom">
                        <div className="row invoceCantiner1">
                          <div className="col-sm-6">
                            {/* <div>
                              <h3 className="invoice-title-1">Important Note</h3>
                              <ul className="important-notes-list-1">
                                <li>
                                  All amounts shown on this invoice are in US
                                  dollars
                                </li>
                                <li>
                                  finance charge of 1.5% will be made on unpaid
                                  balances after 30 days.
                                </li>
                                <li>Once order done, money can't refund</li>
                                <li>
                                  Delivery might delay due to some external
                                  dependency
                                </li>
                              </ul>
                            </div> */}
                          </div>
                          <div className="col-sm-6 col-offsite">
                            <div className="text-end">
                              <p className="mb-0 text-13">
                                Thank you for your business
                              </p>
                              <p>
                                {/* <strong> {get_invoice?.name}</strong> */}
                              </p>
                              {/* <div className="mobile-social-icon mt-50 print-hide">
                                <h6>Follow Us</h6>
                                <a href="#">
                                  <img
                                    src="/assets/imgs/theme/icons/icon-facebook-white.svg"
                                    alt=""
                                  />
                                </a>
                                <a href="#">
                                  <img
                                    src="/assets/imgs/theme/icons/icon-twitter-white.svg"
                                    alt=""
                                  />
                                </a>
                                <a href="#">
                                  <img
                                    src="/assets/imgs/theme/icons/icon-instagram-white.svg"
                                    alt=""
                                  />
                                </a>
                                <a href="#">
                                  <img
                                    src="/assets/imgs/theme/icons/icon-pinterest-white.svg"
                                    alt=""
                                  />
                                </a>
                                <a href="#">
                                  <img
                                    src="/assets/imgs/theme/icons/icon-youtube-white.svg"
                                    alt=""
                                  />
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="invoice-btn-section clearfix d-print-none">
                      <a
                        href="javascript:window.print()"
                        className="btn btn-lg btn-custom btn-print hover-up"
                      >
                        {" "}
                        <img
                          src="assets/imgs/theme/icons/icon-print.svg"
                          alt=""
                        />{" "}
                        Print{" "}
                      </a>
                      <a
                        id="invoice_download_btn"
                        className="btn btn-lg btn-custom btn-download hover-up"
                      >
                        {" "}
                        <img
                          src="assets/imgs/theme/icons/icon-download.svg"
                          alt=""
                        />{" "}
                        Download{" "}
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ReactToPrint
              trigger={() => (
                <Button variant="primary" onClick={hendleDelete}>
                  Print / Download
                </Button>
                // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
                //   Print / Download
                // </button>
              )}
              content={() => componentRef.current}
            />

            {/* <Button variant="secondary" onClick={handleCloseDelete}>
              No
            </Button>
            <Button variant="primary" onClick={hendleDelete}>
              Yes
            </Button> */}
          </Modal.Footer>
        </Modal>

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Your Wallet History</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div>
              {/* <a href="#" className="btn btn-light rounded font-md">Export</a>
                      <a href="#" className="btn btn-light rounded font-md">Import</a> */}
              {/* <a href="#" className="btn btn-primary btn-sm rounded">
              Create new
            </a> */}

              {/* <Button variant="primary" onClick={handleShow}>
                Create Product
              </Button> */}
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header1">
              <div className="row align-items-center">
                {/* <div className="col col-check flex-grow-0">
                <div className="form-check ms-2">
                  <input className="form-check-input" type="checkbox" value="" />
                </div>
              </div> */}
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  {/* <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='currentColor'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span> */}
                  {/* <!--end::Svg Icon-->  */}
                  {/* <input
                    type="text"
                    data-kt-permissions-table-filter="search"
                    className="form-control form-control-solid w-250px ps-15"
                    placeholder="Search Product"
                    onChange={(e) => {
                      hendleSearch(e.target.value);
                    }}
                  /> */}
                  {/* <select className="form-select">
                                  <option selected>All category</option>
                                  <option>Electronics</option>
                                  <option>Clothes</option>
                                  <option>Automobile</option>
                              </select> */}
                </div>
                <div className="col-md-2 col-6">
                  {/* <input type="date" value="02.05.2021" className="form-control" /> */}
                </div>
                <div className="col-md-2 col-6">
                  {/* <select className="form-select">
                  <option selected>Status</option>
                  <option>Active</option>
                  <option>Disabled</option>
                  <option>Show all</option>
                </select> */}
                </div>
              </div>
            </header>

            <div className="card-body">
              <table>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>User Name</th>
                    <th>Amount</th>
                    <th>date</th>
                    <th> Transation Id</th>
                    <th>Status</th>
                    {/* <th>Description</th> */}
                  </tr>
                </thead>
                <tbody>
                  {commentsData &&
                    commentsData.map((data, i) => {
                      const nextMonthStart = moment(data?.createdAt)
                        .add(1, "month")
                        .toDate();
                      const formetCahnge =
                        moment(nextMonthStart).format("DD MMM yyyy");

                      return (
                        <tr key={i}>
                          <td>{(page - 1) * 10 + i + 1}</td>
                          <td>{data?.userName}</td>
                          <td>{data?.amount}</td>
                          <td style={{ minWidth: "150px" }}>
                            {moment(data?.createdAt).format("DD MMM yyyy")}
                          </td>
                          <td>{data?.transationId}</td>
                          <td>{data?.status}</td>
                          <td>{data?.description}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {/* <article className="itemlist">
                <div className="row align-items-center">
                  <div className="col-lg-2 col-sm-2 col-4 col-price">
                    <span>User Name</span>
                  </div>

                  <div className="col-lg-2 col-sm-2 col-4 col-price">
                    <span>Amount</span>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-price">
                    <span> Transation Id</span>
                  </div>

                  <div className="col-lg-2 col-sm-2 col-4 col-date">
                    <span>Status</span>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-date">
                    <span>date</span>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-date">
                    <span>Description</span>
                  </div>
                </div>
              </article>
              {commentsData &&
                commentsData?.map((data, i) => {
                  const nextMonthStart = moment(data?.createdAt)
                    .add(1, "month")
                    .toDate();
                  const formetCahnge =
                    moment(nextMonthStart).format("DD MMM yyyy");

                  return (
                    <article className="itemlist" key={i}>
                      <div className="row align-items-center">
                        <div className="col-lg-2 col-sm-2 col-4 col-status">
                          <span>{data?.userName}</span>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-4 col-status">
                          <span>{data?.amount}</span>
                        </div>

                        <div className="col-lg-2 col-sm-2 col-4 col-status">
                          <span>{data?.transationId}</span>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-4 col-status">
                          <span>{data?.status}</span>
                        </div>

                        <div className="col-lg-2 col-sm-2 col-4 col-date">
                          <span>
                            {moment(data?.createdAt).format("DD MMM yyyy")}
                          </span>
                        </div>
                        <div className="col-lg-2 col-sm-2 col-4 col-status">
                          <span>{data?.description}</span>
                        </div>
                      </div>
                    </article>
                  );
                })} */}

              {get_seller_product_list?.totalDocs == "0" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <h3>Record Not found</h3>
                </div>
              )}

              {/*    <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/3.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Gortons Beer Battered Fish Fillets</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$76.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-warning">
                    Archived
                  </span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/4.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">
                        Foster Farms Takeout Crispy Classic Buffalo
                      </h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$18.00</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/3.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">
                        Blue Diamond Almonds Lightly Salted
                      </h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$76.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-danger">
                    Disabled
                  </span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/5.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">
                        Chobani Complete Vanilla Greek Yogurt
                      </h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$18.00</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-warning">
                    Archived
                  </span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/6.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Canada Dry Ginger Ale 2 L Bottle</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$76.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/4.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Gortons Beer Battered Fish Fillets</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$18.00</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/3.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Gortons Beer Battered Fish Fillets</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$76.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/4.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Haagen-Dazs Caramel Cone Ice</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$180.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article> */}
            </div>
          </div>

          <div className="pagination-area mt-30 mb-50">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              className="dataTables_paginate paging_simple_numbers category"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
            {/* <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-start">
              <li className="page-item active">
                <a className="page-link" href="#">
                  01
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  02
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  03
                </a>
              </li>
              <li className="page-item">
                <a className="page-link dot" href="#">
                  ...
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  16
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  <i className="material-icons md-chevron_right"></i>
                </a>
              </li>
            </ul>
          </nav> */}
          </div>
        </section>
        {/* <!-- content-main end// --> */}
        {/* <Footer /> */}
      </main>
    </div>
  );
}

export default Wallet;
