import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import { allapiAction } from "../../Redux/common/action";
// import { URL } from "../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import ScrollToTop from "../ScrollToTop/ScrollToTop";
// import { NextSeo, ProductJsonLd } from "next-seo";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  PinterestShareButton,
  PinterestIcon,
  PocketShareButton,
  PocketIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
} from "react-share";
// import { setUserName, setUserNumber } from "@/Utils/Auth/Token";
// import { URL } from "@/Redux/common/url";
// import { useRouter } from "next/router";

// import Link from "next/link";
// import { allapiAction } from "@/Redux/common/action";
// import Head from "next/head";
// import Image from "next/image";

import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import TruncateText from "../TruncateText/TruncateText";
import { Link, useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
// import Seo from "../Seo/Seo";
import { setUserName, setUserNumber } from "../../Utils/Auth/Token";
import { URL } from "../../Redux/common/url";
import HeaderMain from "../Layout/HeaderMain";
import FooterMain from "../Layout/FooterMain";

function ProductDetails({ post, reletedProduct }) {
  //   const data = useParams();
  const dispatch = useDispatch();
  //   const navigate = useNavigate();

  const router = useNavigate();
  const { id } = useParams();
  const data = id;
  const [productImage, setproductImage] = useState([]);
  const [productColor, setproductColor] = useState("");
  const [productSize, setproductSize] = useState({});
  const [productSizeList, setproductSizeList] = useState([]);

  console.log(post);
  console.log(reletedProduct);

  const hendleToAddItme = (e, id) => {
    // setactiveCate(e);

    if (productSize?.stock > 0) {
      dispatch(allapiAction.AddCartItme(e, productColor, productSize?._id));
    } else {
      toast.error("stock not available");
    }
  };

  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      // Outputs: '/blog/react-get-current-url/'

      pageUrl = window.location.href;
    }
  }

  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Copied!");
  }

  // const get_product_details = post;

  // console.log(get_product_details);

  const get_product_details = useSelector((state) =>
    state?.allapi?.get_product_details ? state?.allapi?.get_product_details : {}
  );

  const [dispImg, setDispImg] = useState("");

  useEffect(() => {
    dispatch(allapiAction.productDetails(id));
    dispatch(allapiAction.getsubsubcatehome({}));
    // return () => {};
  }, [id]);
  // console.log(get_product_details?.subcolor);
  useEffect(() => {
    if (get_product_details?.subcolor) {
      const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

      setproductColor(get_product_details?.subcolor[0]?._id);
      setproductSize(get_product_details?.subcolor[0]?.productsize[0]);
      setproductSizeList(get_product_details?.subcolor[0]?.productsize);

      const allImg = get_product_details?.subcolor[0]?.images?.map((data) => {
        return URL.API_BASE_URL + data?.img;
      });

      // console.log(allImg);

      // if (get_product_details?.images !== undefined) {
      const allImgs = allImg?.filter((data) => {
        return !data.match("undefined");
      });

      // const allimgMarge = [feImg, ...allImgs];
      setDispImg(allImg[0]);
      setproductImage(allImg);
      // }
    }
  }, [get_product_details]);

  const [showWhatsappIn, setShowWhatsappIn] = useState(false);
  const handleCloseWhatsappIn = () => setShowWhatsappIn(false);
  const handleOpneWhatsappIn = () => setShowWhatsappIn(true);

  const [showEmailIn, setShowEmailIn] = useState(false);
  const handleCloseEmailIn = () => setShowEmailIn(false);
  const handleOpneEmailIn = () => setShowEmailIn(true);

  const [whatInq, setwhatInq] = useState({ countryCode: "", whatsappNo: "" });
  const [emailInq, setemailInq] = useState({
    country: "",
    customerEmail: "",
    userName: "",
  });

  const hendleToWhatsapp = (e) => {
    const { name, value } = e.target;
    setwhatInq({ ...whatInq, [name]: value });
  };

  const hendleToEmail = (e) => {
    const { name, value } = e.target;
    setemailInq({ ...emailInq, [name]: value });
  };

  // start

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode1, setCountryCode1] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");

  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [countryCode12, setCountryCode12] = useState("");
  const [phoneNumber12, setPhoneNumber12] = useState("");
  const handlePhoneChange = (value) => {
    const phone = value || "";
    const phoneNumberObj = parsePhoneNumberFromString(phone);
    const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
    const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
    setCountryCode1(conCode);
    setPhoneNumber1(contry);
    setPhoneNumber(value);
  };

  const handlePhoneChange2 = (value) => {
    const phone = value || "";
    const phoneNumberObj = parsePhoneNumberFromString(phone);
    const conCode = phoneNumberObj ? phoneNumberObj.countryCallingCode : "";
    const contry = phoneNumberObj ? phoneNumberObj.nationalNumber : "";
    setCountryCode12(conCode);
    setPhoneNumber12(contry);
    setPhoneNumber2(value);
  };

  const hendleWhatsappIn = () => {
    // whatsappinquiresubmit

    if (!countryCode1) {
      toast.error("Please enter country code");
    } else if (!phoneNumber1) {
      toast.error("Please enter Number");
    } else if (!whatInq?.name) {
      toast.error("Please enter name");
    } else {
      dispatch(
        allapiAction.whatsappinquiresubmit({
          countryCode: countryCode1,
          whatsappNo: phoneNumber1,
          name: whatInq?.name,
          email: whatInq?.email,
          message: whatInq?.message,
          productName: get_product_details?.title,
          productId: get_product_details?._id,
          sellerId: get_product_details?.sellerId?._id,
          sellerName: get_product_details?.sellerId?.fullName,
          sellercountryCode: get_product_details?.sellerId?.countryCode,
          sellerwhatsappNo: get_product_details?.sellerId?.whatsappNumber,
        })
      );
      const userEmailData = JSON.stringify({
        countryCode: countryCode1,
        whatsappNo: phoneNumber1,
        name: whatInq?.name,
        email: whatInq?.email,
      });

      // whatsappNo: whatInq?.whatsappNo,
      // setUserNumber(userEmailData);
      // dispatch(allapiAction.getsubsubcatehome({}));
      setShowWhatsappIn(false);
      // setShowView(false);

      return () => {};
    }
  };

  // end

  // start

  const hendleEmailIn = () => {
    // whatsappinquiresubmit

    if (!emailInq?.userName) {
      toast.error("Please enter name");
    } else if (!emailInq?.customerEmail) {
      toast.error("Please enter email");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(emailInq?.customerEmail)
    ) {
      toast.error("invalid email");
    } else if (!phoneNumber12) {
      toast.error("Please enter number");
    } else {
      dispatch(
        allapiAction.emailenquirysubmit({
          // country: "", customerEmail: "",userName:""
          countryCode: countryCode12,
          whatsappNo: phoneNumber12,
          message: emailInq?.message,
          customerEmail: emailInq?.customerEmail,
          userName: emailInq?.userName,
          productName: get_product_details?.title,
          productId: get_product_details?._id,
          sellerId: get_product_details?.sellerId?._id,
          sellerName: get_product_details?.sellerId?.fullName,
          sellerEmail: get_product_details?.sellerId?.email,
          sellerwhatsappNo: get_product_details?.sellerId?.whatsappNumber,
        })
      );
      const userEmailData = JSON.stringify({
        countryCode: countryCode12,
        whatsappNo: phoneNumber12,
        Name: emailInq?.userName,
        Email: emailInq?.customerEmail,
        // message: emailInq?.message,
      });

      setUserName(userEmailData);
      // dispatch(allapiAction.getsubsubcatehome({}));
      setShowEmailIn(false);
      // setShowView(false);

      return () => {};
    }
  };

  useEffect(() => {
    const blogIds = localStorage.getItem("access_name");
    const blogIdss = blogIds !== null ? blogIds : "{}";
    const arr = JSON.parse(blogIdss);

    const blogIdsnum = localStorage.getItem("access_number");
    const blogIdssnum = blogIdsnum !== null ? blogIdsnum : "{}";
    const arrnum = JSON.parse(blogIdssnum);

    setPhoneNumber("+" + arrnum?.countryCode + arrnum?.whatsappNo);
    setPhoneNumber2("+" + arr?.countryCode + arr?.whatsappNo);

    setCountryCode1(arrnum?.countryCode);
    setPhoneNumber1(arrnum?.whatsappNo);
    setCountryCode12(arr?.countryCode);
    setPhoneNumber12(arr?.whatsappNo);

    setwhatInq({
      name: arrnum?.name,
      email: arrnum?.email,
    });
    setemailInq({
      // message: arr?.message,
      customerEmail: arr?.Email,
      userName: arr?.Name,
    });
  }, []);

  const urltet = URL.API_BASE_URL_2 + "/product-details/";

  const feImg = URL.API_BASE_URL + get_product_details?.featureImage;

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
    } else {
      setValue("");
    }
  }, []);

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine the width and height based on screen width
  const imageWidth = windowWidth <= 767 ? 150 : 300;
  const imageHeight = windowWidth <= 767 ? 150 : 229;

  return (
    <div>
      <HeaderMain />
      {/* <NextSeo
        title={post?.title}
        description={post?.title}
        canonical={urltet + id}
        openGraph={{
          // type: "website",
          locale: "en",

          siteName: "Jewelslly",
          url: urltet + id,
          title: post?.title,
          description: post?.title,
          type: "website",
          // article: {
          //   publishedTime: post?.createdAt,
          //   modifiedTime: post?.updatedAt,

          // },
          images: [
            {
              url: URL.API_BASE_URL + post?.featureImage,
              width: 800,
              height: 600,
              alt: post?.title,
              type: "image/jpeg",
            },
          ],
        }}
        twitter={{
          handle: "@handle",
          site: "@jewelslly",
          cardType: "summary_large_image",
          title: post?.title,
          description: post?.title,
        }}
      /> */}

      {/* <ProductJsonLd
        productName={post?.title}
        images={[URL.API_BASE_URL + post?.featureImage]}
        description={post?.title}
        // brand="ACME"
        // color="blue"
        // manufacturerName="Gary Meehan"
        // manufacturerLogo="https://www.example.com/photos/logo.jpg"
        // material="steel"
        // slogan="For the business traveller looking for something to drop from a height."
        // disambiguatingDescription="Executive Anvil, perfect for the business traveller."
        // releaseDate="2014-02-05T08:00:00+08:00"
        // productionDate="2015-02-05T08:00:00+08:00"
        // purchaseDate="2015-02-06T08:00:00+08:00"
        // award="Best Executive Anvil Award."

        // mpn="925872"

        aggregateRating={{
          ratingValue: "4.4",
          reviewCount: "89",
        }}

        //   reviews={[
        //   {
        //     author: 'jewelslly',
        //     datePublished: '2017-01-06T03:37:40Z',
        //     reviewBody:
        //       '',
        //     name: 'jewelslly',
        //     reviewRating: {
        //       bestRating: '5',
        //       ratingValue: '5',
        //       worstRating: '1',
        //     },
        //     publisher: {
        //       type: 'Organization',
        //       name: 'jewelslly',
        //     },
        //   },
        // ]}
      /> */}
      {/* ogpdesc */}

      {/* whatsapp model  */}
      <Modal show={showWhatsappIn} onHide={handleCloseWhatsappIn}>
        <Modal.Header closeButton>
          <Modal.Title>Whatsapp Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              {/* <div className="mb-3">
                <label for="title" className="form-label">
                  Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Your Name"
                  type="text"
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div> */}

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Name
                </label>
                <input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  value={whatInq?.name}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Email
                </label>
                <input
                  name="email"
                  className="form-control"
                  placeholder="Enter Email"
                  type="email"
                  value={whatInq?.email}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label for="teg" className="form-label">
                  Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  country="IN"
                  defaultCountry="IN"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter message"
                  type="text"
                  value={whatInq?.message}
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div>
              {/* <div className="mb-3">
                <label for="teg" className="form-label">
                  Inquire
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter Inquire"
                  type="text"
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseWhatsappIn}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleWhatsappIn();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* email model  */}
      <Modal show={showEmailIn} onHide={handleCloseEmailIn}>
        <Modal.Header closeButton>
          <Modal.Title>Email Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              {/* <div className="mb-3">
                <label for="title" className="form-label">
                  Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Your Name"
                  type="text"
                  onChange={(e) => {
                    hendleToWhatsapp(e);
                  }}
                />
              </div> */}

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Your Name
                </label>
                <input
                  name="userName"
                  className="form-control"
                  placeholder="Enter Name"
                  type="text"
                  value={emailInq?.userName}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Email
                </label>
                <input
                  name="customerEmail"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  value={emailInq?.customerEmail}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label for="teg" className="form-label">
                  Number
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  country="IN"
                  defaultCountry="IN"
                  value={phoneNumber2}
                  onChange={handlePhoneChange2}
                />
              </div>
              <div className="mb-3">
                <label for="teg" className="form-label">
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Enter Message"
                  type="text"
                  value={emailInq?.message}
                  onChange={(e) => {
                    hendleToEmail(e);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEmailIn}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              hendleEmailIn();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={open} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row unishareIcon">
            <div className="col-2">
              <FacebookShareButton
                url={pageUrl}
                // quote={blog?.heading}
              >
                <FacebookIcon size={26} round={true} onClick={onCloseModal} />
              </FacebookShareButton>
            </div>
            <div className="col-2">
              <TwitterShareButton url={pageUrl}>
                <TwitterIcon size={26} round={true} onClick={onCloseModal} />
              </TwitterShareButton>
            </div>
            <div className="col-2">
              <WhatsappShareButton url={pageUrl}>
                <WhatsappIcon size={26} round={true} onClick={onCloseModal} />
              </WhatsappShareButton>
            </div>
            <div className="col-2">
              <LinkedinShareButton url={pageUrl}>
                <LinkedinIcon size={26} round={true} onClick={onCloseModal} />
              </LinkedinShareButton>
            </div>
            <div className="col-2">
              <EmailShareButton url={pageUrl}>
                <EmailIcon size={26} round={true} onClick={onCloseModal} />
              </EmailShareButton>
            </div>
            <div className="col-2">
              <MailruShareButton url={pageUrl}>
                <MailruIcon size={26} round={true} onClick={onCloseModal} />
              </MailruShareButton>
            </div>
            <div className="col-2">
              <OKShareButton url={pageUrl}>
                <OKIcon size={26} round={true} onClick={onCloseModal} />
              </OKShareButton>
            </div>
            <div className="col-2">
              <PinterestShareButton url={pageUrl}>
                <PinterestIcon size={26} round={true} onClick={onCloseModal} />
              </PinterestShareButton>
            </div>
            <div className="col-2">
              <PocketShareButton url={pageUrl}>
                <PocketIcon size={26} round={true} onClick={onCloseModal} />
              </PocketShareButton>
            </div>
            <div className="col-2">
              <RedditShareButton url={pageUrl}>
                <RedditIcon size={26} round={true} onClick={onCloseModal} />
              </RedditShareButton>
            </div>
            <div className="col-2">
              <TelegramShareButton url={pageUrl}>
                <TelegramIcon size={26} round={true} onClick={onCloseModal} />
              </TelegramShareButton>
            </div>
            <div className="col-2">
              <TumblrShareButton url={pageUrl}>
                <TumblrIcon size={26} round={true} onClick={onCloseModal} />
              </TumblrShareButton>
            </div>
          </div>

          <div className="shareLinkInModel">
            {" "}
            <p>
              <b>{pageUrl}</b>
            </p>
          </div>

          {/* <div>
          <div
            className="sharebtncos"
            onClick={() => {
              copy();
              onCloseModal();
            }}
          >
            {!copied ? "Copy link" : "Copied!"}
          </div>
        </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              copy();
              onCloseModal();
            }}
          >
            {!copied ? "Copy link" : "Copied!"}
          </Button>
        </Modal.Footer>
      </Modal>

      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to={"/"}>
                <i className="fi-rs-home mr-5"></i>home
              </Link>
              <span></span> {get_product_details?.title}
            </div>
          </div>
        </div>
        <div className="container-fluid mb-30">
          <div className="row">
            <div className="col-xl-12 col-lg-12 m-auto">
              <div className="product-detail accordion-detail">
                <div className="row mb-50 mt-30">
                  <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    <div className="productPage__left">
                      <div className="productPage__sideImagesContainer">
                        {productImage &&
                          productImage?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => setDispImg(item)}
                                className={
                                  dispImg == item
                                    ? "sideImageActive"
                                    : "sideImage "
                                }
                              >
                                <Image
                                  src={item}
                                  alt={get_product_details?.title + index}
                                  width={300}
                                  height={250}
                                  // onError={(e) => {
                                  //   e.target.onerror = null;
                                  //   e.target.src =
                                  //     "../assets/imgs/notfound.png";
                                  // }}
                                />
                              </div>
                            );
                          })}
                      </div>
                      <div className="productPage__displayImageContainer">
                        <div
                          className="productPage__displayImage"
                          // style={{
                          //   backgroundImage: `url(${
                          //     URL.API_BASE_URL + dispImg
                          //   })`,
                          // }}
                        >
                          {/* <div style={{ width: "250px" }}>
                            <img
                              src={dispImg}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </div> */}

                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Wristwatch by Ted Baker London",
                                isFluidWidth: true,
                                src: dispImg,
                                // sizes:
                                //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                              },
                              largeImage: {
                                src: dispImg,
                                width: 1200,
                                height: 1800,
                              },

                              imageClassName: "imgViewS1",

                              enlargedImageStyle: {
                                width: "50px",
                              },
                              lensStyle: {
                                width: "20px",
                                height: "20px",
                              },
                              shouldUsePositiveSpaceLens: true,
                              shouldHideHintAfterFirstActivation: false,
                              // isHintEnabled: true,
                              lensStyle: {
                                backgroundColor: "rgba(0,0,0,.6)",
                              },
                              enlargedImagePosition: "over",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 proRight">
                    <div className="detail-info pr-30 pl-30">
                      <div className="prodetailsbox">
                        <div class="d-flex justify-content-between">
                          {/* <button
                            class="button3 ml-3 mt-3"
                            onClick={onOpenModal}
                          >
                            share
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path
                                d="M13.5759 17.2714L8.46576 14.484C7.83312 15.112 6.96187 15.5 6 15.5C4.067 15.5 2.5 13.933 2.5 12C2.5 10.067 4.067 8.5 6 8.5C6.96181 8.5 7.83301 8.88796 8.46564 9.51593L13.5759 6.72855C13.5262 6.49354 13.5 6.24983 13.5 6C13.5 4.067 15.067 2.5 17 2.5C18.933 2.5 20.5 4.067 20.5 6C20.5 7.933 18.933 9.5 17 9.5C16.0381 9.5 15.1669 9.11201 14.5343 8.48399L9.42404 11.2713C9.47382 11.5064 9.5 11.7501 9.5 12C9.5 12.2498 9.47383 12.4935 9.42408 12.7285L14.5343 15.516C15.167 14.888 16.0382 14.5 17 14.5C18.933 14.5 20.5 16.067 20.5 18C20.5 19.933 18.933 21.5 17 21.5C15.067 21.5 13.5 19.933 13.5 18C13.5 17.7502 13.5262 17.5064 13.5759 17.2714Z"
                                fill="rgba(15,229,107,1)"
                              ></path>
                            </svg>
                          </button> */}

                          {/* {value && (
                            <button class="buttons ml-5  mt-3">
                              {productSize?.afterdiscountprice}{" "}
                            </button>
                          )} */}

                          {value && (
                            <p class="mr-5 mt-3">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                              >
                                <path d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13.5003 8C13.8278 8.43606 14.0625 8.94584 14.175 9.5H16V11H14.175C13.8275 12.7117 12.3142 14 10.5 14H10.3107L14.0303 17.7197L12.9697 18.7803L8 13.8107V12.5H10.5C11.4797 12.5 12.3131 11.8739 12.622 11H8V9.5H12.622C12.3131 8.62611 11.4797 8 10.5 8H8V6.5H16V8H13.5003Z"></path>
                              </svg>
                              {productSize?.price}
                              <br /> */}
                              <div className="pDetails">
                                {value && (
                                  <div class="product-price">
                                    <span>
                                      ₹{productSize?.afterdiscountprice}
                                    </span>
                                    <span class="old-price">
                                      ₹{productSize?.price}
                                    </span>
                                  </div>
                                )}
                              </div>
                              {/* <span>(Incl. Of All Taxes)</span> */}
                            </p>
                          )}
                        </div>

                        <h1 className="title-detail">
                          {get_product_details?.title}
                        </h1>
                        {/* <div className="pDetails">
                          {value && (
                            <div class="product-price">
                              <span>₹{productSize?.afterdiscountprice}</span>
                              <span class="old-price">
                                ₹{productSize?.price}
                              </span>
                            </div>
                          )}
                        </div> */}
                        <div>
                          {productSize?.stock == 0 ? (
                            <p className="text-red-700">
                              {/* {productSize?.stock} */}
                              out of stock
                            </p>
                          ) : (
                            <p className="text-green-700">
                              {productSize?.stock} Available
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="prodetailsbox">
                        <div>
                          <h5>Color</h5>
                          <div>
                            {get_product_details?.subcolor &&
                              get_product_details?.subcolor?.map((data, i) => {
                                const allImg = data?.images?.map((datasss) => {
                                  return URL.API_BASE_URL + datasss?.img;
                                });

                                // console.log(allImg);

                                // if (get_product_details?.images !== undefined) {
                                const allImgs = allImg?.filter((data) => {
                                  return !data.match("undefined");
                                });

                                console.log(allImg);
                                {
                                  /* setDispImg(allImg[0]);
                                setproductImage(allImg); */
                                }
                                return (
                                  <div
                                    key={i}
                                    style={{
                                      display: "inline-block",
                                      width: "30px",
                                      height: "30px",
                                      background: data?.color,
                                      marginRight: "5px",
                                      cursor: "pointer",
                                      border: "1px solid black",
                                    }}
                                    onClick={() => {
                                      setproductColor(data?._id);
                                      setDispImg(allImg[0]);
                                      setproductImage(allImg);
                                      setproductSize(data?.productsize[0]);
                                      setproductSizeList(data?.productsize);
                                    }}
                                  ></div>
                                );
                              })}
                          </div>
                        </div>
                        <hr className="prdehr" />
                        <div>
                          <h5>Size</h5>
                          <div>
                            {productSizeList &&
                              productSizeList?.map((data, i) => {
                                {
                                  /* console.log(data); */
                                }
                                return (
                                  <div
                                    key={i}
                                    className={
                                      data?._id == productSize?._id
                                        ? "selectedSize"
                                        : "unselectedSize"
                                    }
                                    style={{
                                      display: "inline-block",
                                      width: "30px",
                                      height: "30px",
                                      background: data?.color,
                                      marginRight: "5px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setproductSize(data);
                                    }}
                                  >
                                    {data?.size}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="prodetailsbox">
                        <Button
                          variant="success "
                          className="addcrtbtn"
                          onClick={() => {
                            hendleToAddItme(get_product_details?._id);
                          }}
                        >
                          <i className="fi-rs-shopping-cart mr-5"></i>Add
                        </Button>
                        {/* <Button
                          variant="success"
                          className="ml-5"
                          onClick={onOpenModal}
                        >
                          Share
                        </Button> */}
                      </div>
                      <div className="prodetailsbox">
                        <h6>Description</h6>
                        <div
                          className="tab-pane fade show active"
                          // id="Description"
                        >
                          <div
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: get_product_details?.description,
                            }}
                          ></div>
                        </div>
                      </div>

                      <div className="detail-extralink mb-50"></div>
                      <div className="short-desc mb-30"></div>
                      <div className="font-xs">
                        <div className="product-extra-link2 ProBtn"></div>
                      </div>
                    </div>
                    {/* <div className="product-extra-link2 ProBtn">
                      <Button
                        variant="success"
                        onClick={() => {
                          handleOpneEmailIn();
                          dispatch(
                            allapiAction.productclick({
                              productId: data?._id,
                              categoryId: data?.categoryId?._id,
                              sellerId: data?.sellerId?._id,
                              type: "email",
                            })
                          );
                        }}
                      >
                        Email
                      </Button>{" "}
                      {get_product_details?.sellerId?.whatsappVerify && (
                        <Button
                          variant="success"
                          onClick={() => {
                            handleOpneWhatsappIn();
                            dispatch(
                              allapiAction.productclick({
                                productId: data?._id,
                                categoryId: data?.categoryId?._id,
                                sellerId: data?.sellerId?._id,
                                type: "whatsapp",
                              })
                            );
                          }}
                        >
                          Whatsapp
                        </Button>
                      )}{" "}
                      <Button variant="success" onClick={onOpenModal}>
                        Share
                      </Button>{" "}
                    </div> */}
                  </div>
                </div>

                <div className="product-info prodetailsbox1">
                  <div className="tab-style3">
                    <ul class="nav nav-tabs text-uppercase">
                      <li class="nav-item ">
                        <a
                          class="nav-link active prodetailsbox1"
                          id="Description-tab"
                          data-bs-toggle="tab"
                          href="#Description"
                        >
                          Related Product
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="tab-one"
                        role="tabpanel"
                        aria-labelledby="tab-one"
                      >
                        <div className="row product-grid-4">
                          {reletedProduct &&
                            reletedProduct?.map((data, i) => {
                              return (
                                <div
                                  className="col-lg-1-5 col-md-4 col-6 col-sm-6 "
                                  key={i}
                                >
                                  <div
                                    className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn prodetailsbox1"
                                    data-wow-delay=".4s"
                                  >
                                    <div className="product-img-action-wrap">
                                      <div className="product-img product-img-zoom">
                                        <Link
                                          to={
                                            "/product-details/" + data?.pro_sulg
                                          }
                                          // href=""
                                          // onClick={() => {
                                          //   // navigate(
                                          //   //   "/product-details/" + data?.pro_sulg
                                          //   // );
                                          //   // productclick
                                          //   dispatch(
                                          //     allapiAction.productclick({
                                          //       productId: data?._id,
                                          //       categoryId: data?.categoryId?._id,
                                          //       sellerId: data?.sellerId?._id,
                                          //       type: "product",
                                          //     })
                                          //   );
                                          // }}
                                        >
                                          <Image
                                            className="default-img"
                                            src={
                                              URL.API_BASE_URL +
                                              data?.featureImage
                                            }
                                            alt={data?.title}
                                            width={imageWidth}
                                            height={imageHeight}
                                          />
                                        </Link>
                                      </div>
                                      {/* <div className="product-action-1">
                                <a
                                  aria-label="Quick view"
                                  className="action-btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#quickViewModal"
                                  // onClick={() => {
                                  //   hendleModalShowView(data);
                                  // }}
                                >
                                  <i className="fi-rs-eye"></i>
                                </a>
                              </div> */}
                                    </div>
                                    <div className="product-content-wrap">
                                      <div className="product-category">
                                        <Link
                                          to={
                                            "/category-product/" +
                                            data?.categoryId?.cate_sulg
                                          }
                                          onClick={() => {
                                            // navigate(
                                            //   "/category-product/" +
                                            //     data?.categoryId?.cate_sulg
                                            // );
                                          }}
                                        >
                                          {data?.categoryId?.name}
                                        </Link>
                                      </div>
                                      <h2>
                                        <Link
                                          to={
                                            "/product-details/" + data?.pro_sulg
                                          }
                                          onClick={() => {
                                            // navigate(
                                            //   "/product-details/" + data?.pro_sulg
                                            // );
                                            // dispatch(
                                            //   allapiAction.productclick({
                                            //     productId: data?._id,
                                            //     categoryId: data?.categoryId,
                                            //     sellerId: data?.sellerId?._id,
                                            //     type: "product",
                                            //   })
                                            // );
                                          }}
                                        >
                                          {/* {data?.title} */}
                                          <TruncateText
                                            text={data?.title}
                                            maxLength={18}
                                          />
                                        </Link>
                                      </h2>

                                      <div className="product-card-bottom">
                                        {value && (
                                          <div className="product-price">
                                            <span>
                                              ₹{data?.afterdiscountprice}
                                            </span>
                                            <span className="old-price">
                                              ₹{data?.price}
                                            </span>
                                          </div>
                                        )}
                                        {/* <div className="add-cart">
                                  <a className="add"  onClick={(e) => {
                                      hendleToAddItme(data?._id);
                                    }}>
                                    <i className="fi-rs-shopping-cart mr-5"></i>Add{" "}
                                  </a>
                                </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        {/*End product-grid-4*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
      <FooterMain />
    </div>
  );
}

// export async function getServerSideProps({ params }) {
//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   var raw = JSON.stringify({
//     slug: params.slug,
//   });

//   var requestOptions = {
//     method: "GET",
//     headers: myHeaders,
//     // body: raw,
//     redirect: "follow",
//   };

//   const bgl = await fetch(
//     URL.API_BASE_URL + `${URL.productDetails}/${params.id}`,
//     requestOptions
//   );
//   const bg2 = await fetch(
//     URL.API_BASE_URL + `${URL.productDetailsReleted}/${params.id}`,
//     requestOptions
//   );
//   const events = await bgl.json();
//   const events2 = await bg2.json();
//   // const postData = getPostData(slug);
//   const postData = events?.data;
//   const postData2 = events2?.data;

//   if (!events) {
//     return { notFound: true };
//   }

//   return {
//     props: {
//       post: postData,
//       reletedProduct: postData2,
//       // postList: postDataList,
//     },
//   };
// }

export default ProductDetails;
