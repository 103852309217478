// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";

function Shopcart() {
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );

  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);

  const dispatch = useDispatch();

  const hendleCartClear = () => {
    if (localStorage.getItem("access_token")) {
      //   setitmeChange([]);
      dispatch(allapiAction.removeAllCartItem());
      return () => {};
    } else {
      //   setitmeChange([]);
      //   setArr([]);
      localStorage.setItem("cart_product", JSON.stringify([]));
      dispatch(allapiAction.getcartProduct({ data: [] }));
      return () => {};
    }

    // removeAllCartItem
  };

  const removeCart = (e, color, size) => {
    if (localStorage.getItem("access_token")) {
      dispatch(
        allapiAction.removeCartItem({
          //   userid: get_user_profile?.id,
          product_id: e,
          color: color,
          size: size,
        })
      );
      //   setTimeout(() => {
      //     dispatch(allapiAction.getcartitem({}));
      //   }, 500);
      return () => {};
    } else {
      var lists = get_all_cart_itme.filter((x) => {
        return x.product_id != e;
      });

      dispatch(
        allapiAction.getcartProduct({
          data: lists,
          //   userid: get_user_profile?.id,
        })
      );
      //   setArr(lists);
      localStorage.setItem("cart_product", JSON.stringify(lists));
      return () => {};
    }
  };

  const addItmeCount = (e, color, size) => {
    if (localStorage.getItem("access_token")) {
      dispatch(
        allapiAction.addItemCartCount({
          //   userid: get_user_profile?.id,
          product_id: e,
          color: color,
          size: size,
        })
      );
      return () => {};
    } else {
      const finalresult =
        get_all_cart_itme &&
        get_all_cart_itme?.map((data) => {
          data.item = data?.product_id == e ? data?.item + 1 : data?.item;
          return data;
        });
      //   setitmeChange(finalresult);
      //   setArr(finalresult);
      dispatch(
        allapiAction.getcartProduct({
          data: finalresult,
          //   userid: get_user_profile?.id,
        })
      );
      localStorage.setItem("cart_product", JSON.stringify(finalresult));
    }
  };
  const removeItmeCount = (e, color, size) => {
    if (localStorage.getItem("access_token")) {
      dispatch(
        allapiAction.removeItemCartCount({
          //   userid: get_user_profile?.id,
          product_id: e,
          color: color,
          size: size,
        })
      );
      return () => {};
    } else {
      const secondObj = get_all_cart_itme.find((item) => item._id == e);
      if (secondObj?.item > 1) {
        const finalresult =
          get_all_cart_itme &&
          get_all_cart_itme?.map((data) => {
            data.item = data?.product_id == e ? data?.item - 1 : data?.item;
            return data;
          });
        //   setitmeChange(finalresult);
        //   setArr(finalresult);
        dispatch(
          allapiAction.getcartProduct({
            data: finalresult,
            //   userid: get_user_profile?.id,
          })
        );
        localStorage.setItem("cart_product", JSON.stringify(finalresult));
      } else {
        toast.error("minimum quntity 1 select");
      }
    }
  };

  return (
    <div>
      <HeaderMain />
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                <i className="fi-rs-home mr-5"></i>home
              </Link>
              <span></span> Shop
              <span></span> Cart
            </div>
          </div>
        </div>
        <div className="container-fluid mb-80 mt-50">
          <div className="row">
            <div className="col-lg-8 mb-40">
              <h1 className="heading-2 mb-10">Your Cart</h1>
              <div className="d-flex justify-content-between">
                <h6 className="text-body">
                  There are{" "}
                  <span className="text-brand">
                    {get_all_cart_itme?.length}
                  </span>{" "}
                  products in your cart
                </h6>
                <h6 className="text-body">
                  <a
                    onClick={() => {
                      hendleCartClear();
                    }}
                    className="text-muted"
                  >
                    <i className="fi-rs-trash mr-5"></i>Clear Cart
                  </a>
                </h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="shopping-summery">
                <table className="">
                  <thead>
                    <tr className="main-heading">
                      {/* <th className="custome-checkbox start pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="exampleCheckbox11"
                          value=""
                        />
                        <label
                          className="form-check-label"
                          for="exampleCheckbox11"
                        ></label>
                      </th> */}
                      <th scope="col" colspan="2">
                        Product
                      </th>
                      <th scope="col" style={{ minWidth: "100px" }}>
                        Color
                      </th>
                      <th scope="col" style={{ minWidth: "100px" }}>Size</th>
                      <th scope="col" style={{ minWidth: "100px" }}>Unit Price</th>
                      <th scope="col" style={{ minWidth: "100px" }}>Quantity</th>
                      <th scope="col" style={{ minWidth: "100px" }}>Subtotal</th>
                      <th scope="col" className="end" style={{ minWidth: "100px" }}>
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {get_all_cart_itme &&
                      get_all_cart_itme?.map((data, i) => {
                        console.log(data);
                        return (
                          <tr className="pt-30 oncartl" key={i}>
                            {/* <td className="custome-checkbox pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="exampleCheckbox1"
                          value=""
                        />
                        <label
                          className="form-check-label"
                          for="exampleCheckbox1"
                        ></label>
                      </td> */}
                            <td className="image product-thumbnail ">
                              <img
                                src={URL.API_BASE_URL + data?.featureImage}
                                alt={data?.title}
                                height="100px"
                                width={"100px"}
                              />
                            </td>
                            <td className="product-des product-name">
                              <h6 className="mb-5">
                                <Link
                                  className="product-name mb-10 text-heading"
                                  to={"/product-details/" + data?.pro_sulg}
                                >
                                  {data?.title}
                                </Link>
                              </h6>
                              {/* <div className="product-rate-cover">
                                <div className="product-rate d-inline-block">
                                  <div
                                    className="product-rating"
                                    style={{ width: "90%" }}
                                  ></div>
                                </div>
                                <span className="font-small ml-5 text-muted">
                                  {" "}
                                  (4.0)
                                </span>
                              </div> */}
                            </td>
                            <td className="price" data-title="Price">
                              <h6 className="text-body">
                                {data?.productcolorId?.color}{" "}
                              </h6>
                            </td>
                            <td className="price" data-title="Price">
                              <h6 className="text-body quantity">
                                {data?.size}{" "}
                              </h6>
                            </td>
                            <td className="price" data-title="Price">
                              <h4 className="text-body">
                                ₹{data?.afterdiscountprice}{" "}
                              </h4>
                            </td>
                            <td
                              className="text-center detail-info"
                              data-title="Stock"
                            >
                              {/* <div className="detail-extralink mr-15">
                                <div className="detail-qty border radius">
                                  <a
                                    onClick={() => {
                                      removeItmeCount(
                                        data?.productId?._id,
                                        data?.productcolorId?._id,
                                        data?._id
                                      );
                                    }}
                                    className="qty-down"
                                  >
                                    <i className="fi-rs-angle-small-down"></i>
                                  </a>
                                  <span className="qty-val">{data?.item}</span>
                                  <a
                                    onClick={() => {
                                      addItmeCount(
                                        data?.productId?._id,
                                        data?.productcolorId?._id,
                                        data?._id
                                      );
                                    }}
                                    className="qty-up"
                                  >
                                    <i className="fi-rs-angle-small-up"></i>
                                  </a>
                                </div>
                              </div> */}
                              <div class="cart-item">
                                <button
                                  class="quantity-btn"
                                  onClick={() => {
                                    removeItmeCount(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    );
                                  }}
                                >
                                  -
                                </button>
                                <span class="quantity">{data?.item}</span>
                                <button
                                  onClick={() => {
                                    addItmeCount(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    );
                                  }}
                                  class="quantity-btn"
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="price" data-title="Price">
                              <h4 className="text-brand">
                                ₹{data?.subtotalprice}{" "}
                              </h4>
                            </td>
                            <td
                              className="action text-center"
                              data-title="Remove"
                            >
                              <a
                                onClick={(e) => {
                                  removeCart(
                                    data?.productId?._id,
                                    data?.productcolorId?._id,
                                    data?._id
                                  );
                                }}
                                className="text-body"
                              >
                                {/* <i className="fi-rs-trash"></i> */}
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}

                    {/* <tr>
                      <td className="custome-checkbox pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="exampleCheckbox2"
                          value=""
                        />
                        <label
                          className="form-check-label"
                          for="exampleCheckbox2"
                        ></label>
                      </td>
                      <td className="image product-thumbnail">
                        <img src="assets/imgs/shop/product-2-1.jpg" alt="#" />
                      </td>
                      <td className="product-des product-name">
                        <h6 className="mb-5">
                          <a
                            className="product-name mb-10 text-heading"
                            href="shop-product-right.html"
                          >
                            Blue Diamond Almonds Lightly Salted
                          </a>
                        </h6>
                        <div className="product-rate-cover">
                          <div className="product-rate d-inline-block">
                            <div className="product-rating" style={{width:"90%"}}></div>
                          </div>
                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                        </div>
                      </td>
                      <td className="price" data-title="Price">
                        <h4 className="text-body">$3.2 </h4>
                      </td>
                      <td className="text-center detail-info" data-title="Stock">
                        <div className="detail-extralink mr-15">
                          <div className="detail-qty border radius">
                            <a href="#" className="qty-down">
                              <i className="fi-rs-angle-small-down"></i>
                            </a>
                            <span className="qty-val">1</span>
                            <a href="#" className="qty-up">
                              <i className="fi-rs-angle-small-up"></i>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="price" data-title="Price">
                        <h4 className="text-brand">$3.2 </h4>
                      </td>
                      <td className="action text-center" data-title="Remove">
                        <a href="#" className="text-body">
                          <i className="fi-rs-trash"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="custome-checkbox pl-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="exampleCheckbox3"
                          value=""
                        />
                        <label
                          className="form-check-label"
                          for="exampleCheckbox3"
                        ></label>
                      </td>
                      <td className="image product-thumbnail">
                        <img src="assets/imgs/shop/product-3-1.jpg" alt="#" />
                      </td>
                      <td className="product-des product-name">
                        <h6 className="mb-5">
                          <a
                            className="product-name mb-10 text-heading"
                            href="shop-product-right.html"
                          >
                            Fresh Organic Mustard Leaves Bell Pepper
                          </a>
                        </h6>
                        <div className="product-rate-cover">
                          <div className="product-rate d-inline-block">
                            <div className="product-rating" style={{width:"90%"}}></div>
                          </div>
                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                        </div>
                      </td>
                      <td className="price" data-title="Price">
                        <h4 className="text-body">$2.43 </h4>
                      </td>
                      <td className="text-center detail-info" data-title="Stock">
                        <div className="detail-extralink mr-15">
                          <div className="detail-qty border radius">
                            <a href="#" className="qty-down">
                              <i className="fi-rs-angle-small-down"></i>
                            </a>
                            <span className="qty-val">1</span>
                            <a href="#" className="qty-up">
                              <i className="fi-rs-angle-small-up"></i>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="price" data-title="Price">
                        <h4 className="text-brand">$2.43 </h4>
                      </td>
                      <td className="action text-center" data-title="Remove">
                        <a href="#" className="text-body">
                          <i className="fi-rs-trash"></i>
                        </a>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="divider-2 mb-30"></div>
              {/* <div className="cart-action d-flex justify-content-between">
                <a className="btn ">
                  <i className="fi-rs-arrow-left mr-10"></i>Continue Shopping
                </a>
                <a className="btn  mr-10 mb-sm-15">
                  <i className="fi-rs-refresh mr-10"></i>Update Cart
                </a>
              </div> */}
            </div>
            <div className="col-lg-4">
              <div className="border  checkoutbx p-md-4 cart-totals ml-30">
                <div className="table-responsive">
                  <table className="table no-border">
                    <tbody>
                      {/* <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Subtotal</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">
                            ₹{totalSubtotalPrice}
                          </h4>
                        </td>
                      </tr> */}
                      {/* <tr>
                        <td scope="col" colspan="2">
                          <div className="divider-2 mt-10 mb-10"></div>
                        </td>
                      </tr> */}
                      {/* <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Shipping</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h5 className="text-heading text-end">Free</h5>
                        </td>{" "}
                      </tr>{" "} */}
                      {/* <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Estimate for</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h5 className="text-heading text-end">United Kingdom</h5>
                        </td>{" "}
                      </tr> */}
                      {/* <tr>
                        <td scope="col" colspan="2">
                          <div className="divider-2 mt-10 mb-10"></div>
                        </td>
                      </tr> */}
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Amount</h6>
                        </td>
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">
                            ₹{totalSubtotalPrice}
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {get_all_cart_itme?.length > 0 && (
                  <Link to="/checkout-address" className="btn mb-20 w-100">
                    Proceed To CheckOut<i className="fi-rs-sign-out ml-15"></i>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterMain />
    </div>
  );
}

export default Shopcart;
