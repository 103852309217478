import React from "react";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";

function Compliance() {
  return (
    <div>
      <HeaderMain />
      <div class="container-fluid compliance">
        <div class="row">
          <div class="col-xl-12 pic"></div>
        </div>
        <center>
          <div class="row px-5">
            <div class="col-12">
              <p class=" pt-1 pb-5 text-center our">COMPLIANCE</p>
            </div>
          </div>
        </center>

        <div class="row mt-20 mb-20">
          <div class="col-sm-4 toy-1"></div>

          <div class="col-sm-4 toy">
            <div class="row">
              {/* <p class="kit1">
              <a
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                target="_blank"
                href={"/FormC.pdf"}
              >
                Registration Certificate of Establishment
              </a>
            </p>
            <p class="kit1">
              <a
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                target="_blank"
                href={"/iso.pdf"}
              >
                Ios
              </a>
            </p>
            <p class="kit1">
              <a
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                target="_blank"
                href={"/MSME_Renewed.pdf"}
              >
                UDYAM REGISTRATION CERTIFICATE
              </a>
            </p>
            <p class="kit1">
              <a
                style={{
                  textDecoration: "none",
                  color: "slategray",
                  display: "block",
                }}
                target="_blank"
                href={"/AA070721027018I_RC26072021.pdf"}
              >
                Registration Certificate
              </a>
            </p> */}
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/AA070721027018I_RC26072021.pdf"}>
                    <span class="cet">1. Registration Certificate</span>
                  </a>
                </i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/MSME_Renewed.pdf"}>
                    <span class="cet">2. Udyam Registration Certificate</span>
                  </a>
                </i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/iso.pdf"}>
                    <span class="cet">3. ISO Certificate</span>
                  </a>
                </i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/FormC.pdf"}>
                    <span class="cet">4. Establishment Certificate</span>
                  </a>
                </i>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/Permanentaccountnumber.jpg"}>
                    <span class="cet">5. Permanent Account Number</span>
                  </a>
                </i>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/certificateofincorporation.jpg"}>
                    <span class="cet">6. Incorporation Certificate</span>
                  </a>
                </i>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/incometax.jpg"}>
                    <span class="cet">7. Tax Deduction Account No.</span>
                  </a>
                </i>
              </div>
            </div>
            {/* <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/RESOLVEDDIPP.pdf"}>
                    <span class="cet">7. Recognition certificate</span>
                  </a>
                </i>
              </div>
            </div> */}

            {/* <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <span class="cet">
                    5. TAX DEDUCTION AND <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; COLLECTION ACCOUNT
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; NUMBER
                  </span>
                </i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <span class="cet">
                    7. CERTIFICATE OF
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; IMPORTER-EXPORTER
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; CODE
                  </span>
                </i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;<span class="cet">8. INCOME TAX RETURN</span>
                </i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;<span class="cet">9. DECLARATION</span>
                </i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;<span class="cet">10. LIST OF DIRECTORS</span>
                </i>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <span class="cet">
                    11. NODAL OFFICER & <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; GRIEVENCE OFFICER
                  </span>
                </i>
              </div>
            </div>
*/}

            {/* <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/RESOLVEDDIPP.pdf"}>
                    <span class="cet">7. Recognition certificate</span>
                  </a>
                </i> */}
            <div class="row mb-20">
              <div class="col-12">
                <hr style={{ border: "1px solid gray" }} />
                <i class="fa fa-star">
                  &nbsp;
                  <a target="_blank" href={"/RESOLVEDDIPP.pdf"}>
                    <span class="cet">
                      8. Start Up India Recognition Certificate
                    </span>
                  </a>
                </i>
                <hr style={{ border: "1px solid gray" }} />
              </div>
            </div>
          </div>

          <div class="col-sm-4 toy-1"></div>
        </div>
      </div>
      <FooterMain />
    </div>
  );
}

export default Compliance;
