import React, { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "../../../Redux/common/action";
// import { URL } from "../../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { getUserDetail } from "../../Utils2/Auth/Token";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
// import { getUserDetail } from "../../../Utils/Auth/Token";
// import { allapiAction } from "@/Redux/common/action";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
// import { getUserDetail } from "@/Utils/Auth/Token";
// import Cktest from "../../../component/cktetst/Cktest";
// const { getUserDetail } = dynamic(() => import(".@/Utils/Auth/Token"), {
//   ssr: false,
// });

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

// const __DEV__ = document.domain === "localhost";

function Ticket() {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [auth, setauth] = useState(null);

  useEffect(() => {
    const autsh = getUserDetail();
    setauth(autsh);
  }, []);

  const get_seller_ticket_list = useSelector((state) =>
    state?.allapi?.get_seller_ticket_list
      ? state?.allapi?.get_seller_ticket_list
      : {}
  );
  const get_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );

  const all_message_List = useSelector((state) =>
    state?.allapi?.all_message_List ? state?.allapi?.all_message_List : []
  );

  useEffect(() => {
    dispatch(allapiAction.getSellerTicketList(1));
    dispatch(allapiAction.getprofile({}));
    return () => {};
  }, []);

  //   const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    { name: "Route Name", field: "routeName", sortable: true },
    { name: "From", field: "from", sortable: true },
    { name: "To", field: "to", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const comments = get_seller_ticket_list?.docs
    ? get_seller_ticket_list?.docs
    : [];
  const pageCount = get_seller_ticket_list?.totalPages
    ? get_seller_ticket_list?.totalPages
    : 0;

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //   computedComments = computedComments.filter(
    //     (comment) =>
    //       comment.message.toLowerCase().includes(search.toLowerCase()) ||
    //       comment.title.toLowerCase().includes(search.toLowerCase())
    //   );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  //   useEffect(() => {
  //     const endOffset = itemOffset + itemsPerPage;

  //     setPageCount(Math.ceil(get_seller_ticket_list.totalDocs / itemsPerPage));
  //   }, [itemOffset, itemsPerPage, comments]);

  // const handlePageClick = (event: any) => {
  //   const newOffset = event.selected % (comments.length / 2)
  //   setCurrentPage(newOffset + 1)
  //   setItemOffset(newOffset * itemsPerPage)
  // }

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1;

    // setpageNo(data)

    if (!search) {
      // dispatch(ShoetoggelAction.getusers(data))
      dispatch(allapiAction.getSellerTicketList(data));
      //   dispatch(allapiAction.getsellerProductList(1));
      return () => {};
    } else {
      // dispatch(
      //   ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
      // );
      dispatch(allapiAction.searchSellerProduct({ key: search, page: data }));
      return () => {};
    }
  };

  // image uploder
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const [images1, setImages1] = React.useState([]);
  const maxNumber1 = 4;

  const onChange1 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages1(imageList);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  //   const [subList, setsubList] = useState([]);

  //   useEffect(() => {
  //     const dropList = all_sub_sub_categ_List?.map((data, i) => {
  //       data.value = data?._id;
  //       data.label = data?.name;

  //       return data;
  //     });

  //     setsubList(dropList);
  //   }, [all_sub_sub_categ_List]);

  const [roledata, setroledata] = useState({ subject: "", message: "" });

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setroledata({ ...roledata, [name]: value });
  };

  const hendleSearch = (e) => {
    // searchSellerProduct
    setSearch(e);
    // dispatch(allapiAction.searchSellerProduct(e));
    dispatch(allapiAction.searchSellerProduct({ key: e, page: 1 }));
    return () => {};
  };

  const submitProduct = () => {
    try {
      // const {data: auth} = await login(values.role_name, values.tegs)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData();

      // data.append("title", imageEditfor);

      // images

      if (!roledata?.subject) {
        toast.error("please enter subject");
      } else if (!roledata?.message) {
        toast.error("please enter message");
      } else {
        data.append("subject", roledata?.subject);
        data.append("message", roledata?.message);

        dispatch(
          allapiAction.createSellerTickets({
            subject: roledata?.subject,
            message: roledata?.message,
          })
        );
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {}
  };

  async function displayRazorpay(e) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const amountDetails = {
      amounts: productID?.amount,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify(amountDetails),
      redirect: "follow",
    };

    // const data = await fetch('http://localhost:5000/razorpay', requestOptions).then((t) =>
    //     t.json()
    // )

    // const  data = await axios.post('http://192.168.0.65:5500/razorpay', amountDetails, {
    //   headers : {
    //     'Content-Type': 'text/plain'
    //   }
    // }).then((t) =>
    //  t.json()
    //   )

    const options = {
      key: /*  __DEV__ ? */ "rzp_test_4Wrr7LEnAa53c0" /* : 'PRODUCTION_KEY' */,
      currency: "INR",
      amount: 100 * productID?.amount.toString(),
      // order_id: data.id,
      name: "Rayna Jwellery",
      description: "Thank you for nothing. Please give us some money",
      image: "/assets/img/logo/logo3.jpeg",
      handler: (res) => {
        dispatch(
          allapiAction.createPurchase({
            packageId: productID?._id,
            packageName: productID?.name,
            amount: productID?.amount,
            transationId: res?.razorpay_payment_id,
          })
        );
      },

      prefill: {
        name: get_profile?.fullName,
        email: get_profile?.email,
        contact: get_profile?.whatsappNumber,
      },
      notes: {
        userId: "31",
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    paymentObject.open();
  }

  // deleteSellerProduct

  const [showDelelt, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const [productID, setproductID] = useState("");

  const hendleModalShow = (e) => {
    setShowDelete(true);
    setproductID(e);

    // getSellerTicketList
    dispatch(
      allapiAction.getmessageSupportList({
        ticket_id: e?._id,
        user_id: e?.user_id?._id,
      })
    );
    return () => {};
  };

  const hendleDelete = () => {
    // deleteProduct
    setShowDelete(false);

    if (productID?.amount == 0) {
      dispatch(
        allapiAction.createPurchase({
          packageId: productID?._id,
          packageName: productID?.name,
          amount: productID?.amount,
          transationId: "free",
        })
      );
    } else {
      displayRazorpay();
    }

    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  //   createSellerTickets

  const [enterMessage, setenterMessage] = useState("");

  const createMessage = () => {
    // createMessage

    try {
      const data = new FormData();

      if (!enterMessage) {
        toast.error("please enter message");
      } else {
        dispatch(
          allapiAction.createMessage({
            ticket_id: productID?._id,
            message: enterMessage,
          })
        );

        setenterMessage("");
      }
    } catch (error) {}
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      createMessage();
    }
  };

  return (
    <div>
      <Sidebar />

      <main className="main-wrap">
        <HeaderAccount />

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Support</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="card mx-auto ">
              <div className="card-body">
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className="mb-3">
                  <label for="subject" className="form-label">
                    Subject
                  </label>
                  {/* <input
                    name="subject"
                    className="form-control"
                    placeholder="Enter Subject"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  /> */}
                  <select
                    className="form-control"
                    name="subject"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                    value={roledata?.subject}
                  >
                    <option value={""}>Select </option>
                    <option value={"KYC Related"}>KYC Related</option>
                    <option value={"Rank Upgradation Related"}>
                      Rank Upgradation Related
                    </option>
                    <option value={"Product Related"}>Product Related</option>
                    <option value={"Product Refund Related"}>
                      Product Refund Related
                    </option>
                    <option value={"Income Related"}>Income Related</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label for="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    name="message"
                    className="form-control"
                    placeholder="Enter Message"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                submitProduct();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDelelt} size="lg" onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title> Conversation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="card w-100 rounded-0 border-0"
              id="kt_drawer_chat_messenger"
            >
              {/*-begin::Card header */}
              <div
                className="card-header pe-5"
                id="kt_drawer_chat_messenger_header"
              >
                {/*-begin::Title */}
                <div className="card-title">
                  {/*-begin::User */}
                  <div className="d-flex justify-content-center flex-column me-3">
                    <h4>{productID?.subject}</h4>
                    {/* <a
                      href="#"
                      className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                    >
                    
                    </a> */}
                    {/*-begin::Info */}
                    {/* <div className="mb-0 lh-1">
                      <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                      <span className="fs-7 fw-semibold text-muted">
                        Active
                      </span>
                    </div> */}
                    {/*-end::Info */}
                  </div>
                  {/*-end::User */}
                </div>
                {/*-end::Title */}
                {/*-begin::Card toolbar */}

                {/*-end::Card toolbar */}
              </div>
              {/*-end::Card header */}
              {/*-begin::Card body */}

              <div className="modal-content">
                {/* <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="modal-body minHeCate">
                  <div className="row">
                    {all_message_List &&
                      all_message_List?.map((data, i) => {
                        const userId = auth?._id;

                        console.log(data);
                        console.log(auth);
                        console.log(userId);
                        console.log(data?.user_id?._id);
                        console.log(userId !== data?.user_id?._id);

                        return userId !== data?.user_id?._id ? (
                          <div
                            className="col-md-12 col-sm-12 col-xs-12 mb-md-0 mb-sm-5"
                            key={i}
                          >
                            <h5 className="title-detail">
                              {data?.message}
                              {/* <a
                           
                                className="text-heading"
                              >
                                {"productData?.title"}
                              </a> */}
                            </h5>

                            <div className="font-xs ">
                              <ul>
                                <li className="mb-5">
                                  Admin
                                  {/* <span className="text-brand">
                                {"productData?.tegs"}
                              </span> */}
                                </li>
                                {/* <li className="mb-5">
                              Category:
                              <span className="text-brand">
                                {" "}
                                {"productData?.categoryName"}
                              </span>
                            </li>
                            <li className="mb-5">
                              Status:
                              <span className="text-brand">
                                {" "}
                                {"productData?.status"}
                              </span>
                            </li> */}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-12 col-sm-12 col-xs-12 text-end">
                            <div className="detail-info pr-30 pl-30">
                              <h5 className="title-detail">{data?.message}</h5>

                              <div className="font-xs ">
                                <ul>
                                  <li className="mb-5">
                                    You
                                    {data?.readm == "0" ? (
                                      <img
                                        src="/assets/imgs/1.jpg"
                                        alt=""
                                        style={{ width: "20px" }}
                                      />
                                    ) : (
                                      <img
                                        src="/assets/imgs/2.jpg"
                                        alt=""
                                        style={{ width: "20px" }}
                                      />
                                    )}
                                    {/* <span className="text-brand">
                                {"productData?.tegs"}
                              </span> */}
                                  </li>
                                  {/* <li className="mb-5">
                              Category:
                              <span className="text-brand">
                                {" "}
                                {"productData?.categoryName"}
                              </span>
                            </li>
                            <li className="mb-5">
                              Status:
                              <span className="text-brand">
                                {" "}
                                {"productData?.status"}
                              </span>
                            </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              {/*-end::Card body */}
              {/*-begin::Card footer */}
              <div className="d-flex mt-3">
                {/*-begin::Input */}
                <input
                  value={enterMessage}
                  //   className="form-control  mb-3"
                  className="form-control"
                  rows={1}
                  data-kt-element="input"
                  placeholder="Type a message"
                  onKeyDown={onEnterPress}
                  onChange={(e) => {
                    setenterMessage(e.target.value);
                  }}
                />
                {/*-end::Input */}
                {/*-begin:Toolbar */}
                <div className="d-flex flex-stack">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      createMessage();
                    }}
                    data-kt-element="send"
                  >
                    Send
                  </button>
                  {/*-end::Send */}
                </div>
                {/*-end::Toolbar */}
              </div>
              {/*-end::Card footer */}
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              No
            </Button>
            <Button variant="primary" onClick={hendleDelete}>
              Yes
            </Button>
          </Modal.Footer> */}
        </Modal>

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Ticket List</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div>
              {/* <a href="#" className="btn btn-light rounded font-md">Export</a>
                    <a href="#" className="btn btn-light rounded font-md">Import</a> */}
              {/* <a href="#" className="btn btn-primary btn-sm rounded">
            Create new
          </a> */}

              <Button variant="primary" onClick={handleShow}>
                Raise a Request
              </Button>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header1">
              <div className="row align-items-center">
                {/* <div className="col col-check flex-grow-0">
              <div className="form-check ms-2">
                <input className="form-check-input" type="checkbox" value="" />
              </div>
            </div> */}
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  {/* <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='17.0365'
                      y='15.1223'
                      width='8.15546'
                      height='2'
                      rx='1'
                      transform='rotate(45 17.0365 15.1223)'
                      fill='currentColor'
                    />
                    <path
                      d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                      fill='currentColor'
                    />
                  </svg>
                </span> */}
                  {/* <!--end::Svg Icon-->  */}
                  {/* <input
                    type="text"
                    data-kt-permissions-table-filter="search"
                    className="form-control form-control-solid w-250px ps-15"
                    placeholder="Search Product"
                    onChange={(e) => {
                      hendleSearch(e.target.value);
                    }}
                  /> */}
                  {/* <select className="form-select">
                                <option selected>All category</option>
                                <option>Electronics</option>
                                <option>Clothes</option>
                                <option>Automobile</option>
                            </select> */}
                </div>
                <div className="col-md-2 col-6">
                  {/* <input type="date" value="02.05.2021" className="form-control" /> */}
                </div>
                <div className="col-md-2 col-6">
                  {/* <select className="form-select">
                <option selected>Status</option>
                <option>Active</option>
                <option>Disabled</option>
                <option>Show all</option>
              </select> */}
                </div>
              </div>
            </header>

            <div className="card-body">
              <table>
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th> Status</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {commentsData &&
                    commentsData.map((data, i) => {
                      const nextMonthStart = moment(data?.createdAt)
                        .add(1, "month")
                        .toDate();
                      const formetCahnge =
                        moment(nextMonthStart).format("DD MMM yyyy");

                      return (
                        <tr key={i}>
                          <td>{data?.subject}</td>

                          <td>
                            {data?.status == "Panding"
                              ? "Pending"
                              : data?.status}
                          </td>

                          <td>
                            {" "}
                            <a
                              onClick={() => {
                                hendleModalShow(data);
                              }}
                              className="btn btn-sm font-sm btn-light rounded"
                            >
                              {" "}
                              {/* <i className="material-icons md-delete_forever"></i>{" "} */}
                              <i className="material-icons done_all"></i>
                              Conversation
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              {get_seller_ticket_list?.totalDocs == "0" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <h3>Record Not found</h3>
                </div>
              )}

              {/*    <article className="itemlist">
            <div className="row align-items-center">
              <div className="col col-check flex-grow-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                <a className="itemside" href="#">
                  <div className="left">
                    <img
                      src="assets/imgs/items/3.jpg"
                      className="img-sm img-thumbnail"
                      alt="Item"
                    />
                  </div>
                  <div className="info">
                    <h6 className="mb-0">Gortons Beer Battered Fish Fillets</h6>
                  </div>
                </a>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-price">
                <span>$76.99</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-status">
                <span className="badge rounded-pill alert-warning">
                  Archived
                </span>
              </div>
              <div className="col-lg-1 col-sm-2 col-4 col-date">
                <span>02.11.2021</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                  {" "}
                  <i className="material-icons md-edit"></i> Edit{" "}
                </a>
                <a href="#" className="btn btn-sm font-sm btn-light rounded">
                  {" "}
                  <i className="material-icons md-delete_forever"></i> Delete{" "}
                </a>
              </div>
            </div>
          </article>

          <article className="itemlist">
            <div className="row align-items-center">
              <div className="col col-check flex-grow-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                <a className="itemside" href="#">
                  <div className="left">
                    <img
                      src="assets/imgs/items/4.jpg"
                      className="img-sm img-thumbnail"
                      alt="Item"
                    />
                  </div>
                  <div className="info">
                    <h6 className="mb-0">
                      Foster Farms Takeout Crispy Classic Buffalo
                    </h6>
                  </div>
                </a>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-price">
                <span>$18.00</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-status">
                <span className="badge rounded-pill alert-success">Active</span>
              </div>
              <div className="col-lg-1 col-sm-2 col-4 col-date">
                <span>02.11.2021</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                  {" "}
                  <i className="material-icons md-edit"></i> Edit{" "}
                </a>
                <a href="#" className="btn btn-sm font-sm btn-light rounded">
                  {" "}
                  <i className="material-icons md-delete_forever"></i> Delete{" "}
                </a>
              </div>
            </div>
          </article>

          <article className="itemlist">
            <div className="row align-items-center">
              <div className="col col-check flex-grow-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                <a className="itemside" href="#">
                  <div className="left">
                    <img
                      src="assets/imgs/items/3.jpg"
                      className="img-sm img-thumbnail"
                      alt="Item"
                    />
                  </div>
                  <div className="info">
                    <h6 className="mb-0">
                      Blue Diamond Almonds Lightly Salted
                    </h6>
                  </div>
                </a>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-price">
                <span>$76.99</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-status">
                <span className="badge rounded-pill alert-danger">
                  Disabled
                </span>
              </div>
              <div className="col-lg-1 col-sm-2 col-4 col-date">
                <span>02.11.2021</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                  {" "}
                  <i className="material-icons md-edit"></i> Edit{" "}
                </a>
                <a href="#" className="btn btn-sm font-sm btn-light rounded">
                  {" "}
                  <i className="material-icons md-delete_forever"></i> Delete{" "}
                </a>
              </div>
            </div>
          </article>

          <article className="itemlist">
            <div className="row align-items-center">
              <div className="col col-check flex-grow-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                <a className="itemside" href="#">
                  <div className="left">
                    <img
                      src="assets/imgs/items/5.jpg"
                      className="img-sm img-thumbnail"
                      alt="Item"
                    />
                  </div>
                  <div className="info">
                    <h6 className="mb-0">
                      Chobani Complete Vanilla Greek Yogurt
                    </h6>
                  </div>
                </a>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-price">
                <span>$18.00</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-status">
                <span className="badge rounded-pill alert-warning">
                  Archived
                </span>
              </div>
              <div className="col-lg-1 col-sm-2 col-4 col-date">
                <span>02.11.2021</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                  {" "}
                  <i className="material-icons md-edit"></i> Edit{" "}
                </a>
                <a href="#" className="btn btn-sm font-sm btn-light rounded">
                  {" "}
                  <i className="material-icons md-delete_forever"></i> Delete{" "}
                </a>
              </div>
            </div>
          </article>

          <article className="itemlist">
            <div className="row align-items-center">
              <div className="col col-check flex-grow-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                <a className="itemside" href="#">
                  <div className="left">
                    <img
                      src="assets/imgs/items/6.jpg"
                      className="img-sm img-thumbnail"
                      alt="Item"
                    />
                  </div>
                  <div className="info">
                    <h6 className="mb-0">Canada Dry Ginger Ale 2 L Bottle</h6>
                  </div>
                </a>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-price">
                <span>$76.99</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-status">
                <span className="badge rounded-pill alert-success">Active</span>
              </div>
              <div className="col-lg-1 col-sm-2 col-4 col-date">
                <span>02.11.2021</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                  {" "}
                  <i className="material-icons md-edit"></i> Edit{" "}
                </a>
                <a href="#" className="btn btn-sm font-sm btn-light rounded">
                  {" "}
                  <i className="material-icons md-delete_forever"></i> Delete{" "}
                </a>
              </div>
            </div>
          </article>

          <article className="itemlist">
            <div className="row align-items-center">
              <div className="col col-check flex-grow-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                <a className="itemside" href="#">
                  <div className="left">
                    <img
                      src="assets/imgs/items/4.jpg"
                      className="img-sm img-thumbnail"
                      alt="Item"
                    />
                  </div>
                  <div className="info">
                    <h6 className="mb-0">Gortons Beer Battered Fish Fillets</h6>
                  </div>
                </a>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-price">
                <span>$18.00</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-status">
                <span className="badge rounded-pill alert-success">Active</span>
              </div>
              <div className="col-lg-1 col-sm-2 col-4 col-date">
                <span>02.11.2021</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                  {" "}
                  <i className="material-icons md-edit"></i> Edit{" "}
                </a>
                <a href="#" className="btn btn-sm font-sm btn-light rounded">
                  {" "}
                  <i className="material-icons md-delete_forever"></i> Delete{" "}
                </a>
              </div>
            </div>
          </article>

          <article className="itemlist">
            <div className="row align-items-center">
              <div className="col col-check flex-grow-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                <a className="itemside" href="#">
                  <div className="left">
                    <img
                      src="assets/imgs/items/3.jpg"
                      className="img-sm img-thumbnail"
                      alt="Item"
                    />
                  </div>
                  <div className="info">
                    <h6 className="mb-0">Gortons Beer Battered Fish Fillets</h6>
                  </div>
                </a>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-price">
                <span>$76.99</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-status">
                <span className="badge rounded-pill alert-success">Active</span>
              </div>
              <div className="col-lg-1 col-sm-2 col-4 col-date">
                <span>02.11.2021</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                  {" "}
                  <i className="material-icons md-edit"></i> Edit{" "}
                </a>
                <a href="#" className="btn btn-sm font-sm btn-light rounded">
                  {" "}
                  <i className="material-icons md-delete_forever"></i> Delete{" "}
                </a>
              </div>
            </div>
          </article>

          <article className="itemlist">
            <div className="row align-items-center">
              <div className="col col-check flex-grow-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-4 col-8 flex-grow-1 col-name">
                <a className="itemside" href="#">
                  <div className="left">
                    <img
                      src="assets/imgs/items/4.jpg"
                      className="img-sm img-thumbnail"
                      alt="Item"
                    />
                  </div>
                  <div className="info">
                    <h6 className="mb-0">Haagen-Dazs Caramel Cone Ice</h6>
                  </div>
                </a>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-price">
                <span>$180.99</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-status">
                <span className="badge rounded-pill alert-success">Active</span>
              </div>
              <div className="col-lg-1 col-sm-2 col-4 col-date">
                <span>02.11.2021</span>
              </div>
              <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                  {" "}
                  <i className="material-icons md-edit"></i> Edit{" "}
                </a>
                <a href="#" className="btn btn-sm font-sm btn-light rounded">
                  {" "}
                  <i className="material-icons md-delete_forever"></i> Delete{" "}
                </a>
              </div>
            </div>
          </article> */}
            </div>
          </div>

          <div className="pagination-area mt-30 mb-50">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              className="dataTables_paginate paging_simple_numbers category"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
            {/* <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-start">
            <li className="page-item active">
              <a className="page-link" href="#">
                01
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                02
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                03
              </a>
            </li>
            <li className="page-item">
              <a className="page-link dot" href="#">
                ...
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                16
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                <i className="material-icons md-chevron_right"></i>
              </a>
            </li>
          </ul>
        </nav> */}
          </div>
        </section>
        {/* <!-- content-main end// --> */}
        {/* <Footer /> */}
      </main>
    </div>
  );
}

export default Ticket;
